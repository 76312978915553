/////////////////////////////////////
// Three Items Selector //////////////
/////////////////////////////////////

.threeItemsSelector__item {
  padding: 20px 20px 20px 94px;
  float: left;
  background-repeat: no-repeat;
  background-position: 34px center;
  @include borderBox();
  cursor: pointer;
  position: relative;

  @include large() {
    border-top: 2px solid $mystic;
    border-bottom: 2px solid $mystic;
  }

  @include small() {
    border-left: 2px solid $mystic;
    border-right: 2px solid $mystic;
  }

  &:nth-child(3) {
    border-right: 2px solid $mystic;
  }

  &:before {
    content: '';
    position: absolute;
    @include animate();
    border: 2px solid $mystic;

    @include large() {
      width: calc(100% - 2px);
      height: 100%;
      top: -2px;
      left: 0px;
    }

    @include small() {
      width: 100%;
      height: calc(100% - 2px);
      top: 0px;
      left: -2px;
    }
  }

  @include large() {
    width: $oneThird;
  }

  @include small() {
    width: 100%;
  }

  &:hover {
    z-index: 2;
  }
}

.threeItemsSelector {
  min-height: 88px;
  margin-bottom: 64px;
  position: relative;

  &.newsletter {
    .threeItemsSelector__item {
      &:nth-child(1) {
        background-image: url('../imgs/kabel-connect-ico.svg');
        background-size: 34px;

        &:hover {
          &:before {
            border: 2px solid $pictonBlue;
          }
        }
      }

      &:nth-child(2) {
        background-image: url('../imgs/kabel-news-flash-ico.svg');
        background-size: 34px;

        &:hover {
          &:before {
            border: 2px solid $fuelYellow;
          }
        }
      }

      &:nth-child(3) {
        background-image: url('../imgs/lifestyle-newsletter-ico.svg');
        background-size: 26px;

        &:hover {
          &:before {
            border: 2px solid $pineGlade;
          }
        }
      }
    }
  }

  &.about {
    .threeItemsSelector__item {

      &:nth-child(1) {
        background-image: url('../imgs/kabel-overview-ico.svg');
        background-size: 30px;

        &:hover {
          &:before {
            border: 2px solid $pictonBlue;
          }
        }
      }

      &:nth-child(2) {
        background-image: url('../imgs/kabel-history-ico.svg');
        background-size: 18px;

        &:hover {
          &:before {
            border: 2px solid $malachite;
          }
        }
      }

      &:nth-child(3) {
        background-image: url('../imgs/kabel-team-ico.svg');
        background-size: 33px;

        &:hover {
          &:before {
            border: 2px solid $coldBurst;
          }
        }
      }
    }
  }

  body.explorer & {
    &.about {
      .threeItemsSelector__item {
        &:nth-child(1) {
          background-image: url('../imgs/kabel-overview-ico.svg');
          background-size: 35px;
        }
      }
    }
  }
}