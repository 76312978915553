/////////////////////////////////////
// Timeline /////////////////////////
/////////////////////////////////////

.timeline {
  width: 100%;
  max-width: 1180px;
  margin: 0 auto;
  position: relative;
  text-align: center;

  &__line {
    position: absolute;
    top: 2%;
    width: 8px;
    height: 98%;
    background-color: $haiti;

    @include customMedia(810px) {
      left: 46.20%;
    }

    @include customMedia(1179px) {
      left: 45.8%;
    }

    @include small() {
      left: 24px;
      top: 2%;
      height: 98%;
    }

    @include customLarge(1180px) {
      left: 36.4%;
    }
  }

  .column {
    @include small() {
      display: inline-block;
    }

    @include small() {
      display: block;
    }
  }

  .row {
    text-align: left;

    @include small() {
      width: 100%;
    }

    div {
      float: left;
    }

    &.ginsburg, &.lifestyle {
      padding-bottom: 64px;
    }

    &.doubleSpace {
      padding-bottom: 102px;
    }

    &.partnership {
      padding-bottom: 120px;
    }

    &.join {
      padding-bottom: 50px;
    }

    &__logo {
      min-height: 72px;
      margin-right: 52px;

      img {
        max-width: 100%;
      }

      @include small() {
        display: none;
      }

      @include customMedia(1179px) {
        width: 40%;
        margin-right: 5%;
      }

      @include customLarge(1180px) {
        width: 368px;
      }

      &.kabel {
        padding-top: 10px;
      }
    }

    &__bullet {
      position: relative;
      padding: 19px 32px 10px;

      @include small() {
        margin-left: 16px;
      }

      &:before {
        content: '';
        width: 26px;
        height: 26px;
        position: absolute;
        top: 22px;
        left: 0;
        border-radius: 100%;
        background-color: $haiti;
      }
    }

    &__arrow {
      position: relative;
      font-size: 50px;

      @include small() {
        padding: 0 18px 10px;
      }

      @include large() {
        padding: 0 2px 10px;
      }
    }

    &__description {
      width: 362px;
      padding-top: 20px;

      @include large() {
        margin-left: 16px;
      }

      @include customMedia(1179px) {
        width: 30%;
      }

      @include small() {
        width: 100%;
        padding-left: 50px;
        box-sizing: border-box;
      }

      &--long {
        @include large() {
          width: 460px;
        }

        @include customMedia(1179px) {
          width: 30%;
        }

        @include small() {
          width: 100%;
          padding-left: 50px;
          padding-right: 20px;
          box-sizing: border-box;
        }
      }
    }

    &__popup {
      position: fixed;
      top: 50%;
      left: 50%;
    }

    a {
      color: $malachite;
      text-decoration: underline;
    }
  }

  .addOn {
    .teamBlock div {
      float: inherit;
    }

    &.modal {
      @include large() {
        width: 70%;
        max-width: 852px;
        padding: 0;
        margin: 0 auto;
        float: initial;
      }

      @include small() {
        width: 90%;
        float: none;
        margin: 0 auto;
      }
    }

    &.modal .teamBlock {
      @include large() {
        width: 90%;
        max-width: 852px;
        padding: 0;
        margin: 32px auto;
        float: initial;
      }

      @include small() {
        width: 80%;
        max-width: 80%;
        float: none;
        margin: 50px auto 20px;
      }

      .teamBlock__description {
        @include small() {
          font-size: 16px;
          line-height: 20px;
        }
      }

      .teamBlock__name {
        @include small() {
          font-size: 24px;
          line-height: 28px;
          margin-bottom: 11px;
        }
      }
    }
  }
}

