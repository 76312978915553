/*-------------------------------------------------------*\
    Grid
/*-------------------------------------------------------*/

$advanced-usage: false !default;

@if $advanced-usage == false {

    .section {
        padding: 4rem 0;
        text-align: center;

        @include break-at(640px){
            padding: 5rem 0;
            text-align: left;
        }

        @include break-at(1200px){
            padding: 5rem 0 9rem 0;
        }
    }
    .container {
        max-width: $grid-width;
        margin: 0 auto;
        padding: 0 1em;
    }

    .row {
        @include clearfix();
    }

    .col {
        margin-bottom: 1em;
        float: left;
    }
    .col:last-child {
        margin-right: 0;
    }

    %full-width {
        width: 100%;
    }

    @for $i from 1 to $columns {
      .w-#{$i} {
        @extend %full-width;
      }
    }

    @media screen and (min-width: 640px) {
      
        @for $i from 1 through $columns {
            .w-#{$i} {
                width: column($i);
                margin-right: percentage($gutter-width, $grid-width);
            }
        }
      
       @for $i from 1 through $columns {
         .p-#{$i} {
             margin-left: column($i) + percentage($gutter-width, $grid-width);
         }
       }
       
    }

}