/////////////////////////////////////
// Home /////////////////////////////
/////////////////////////////////////

.hero {
  @include borderBox();
  padding-bottom: 0;

  @include customLarge($navBreak) {
    padding-left: $gutter;
    padding-right: $gutter;
    margin-top: 65px;
  }

  @include customMedia($navBreak) {
    padding-top: 28px;
  }

  @include medium() {
    padding-left: $gutterMobile;
    padding-right: $gutterMobile;
  }

  .top {
    position: relative;
  }

  .top__title {
    width: 100%;
    @include borderBox();

    @include customLarge($medium-break) {
      padding: 0 323px 0 42px;
    }

    @include customLarge($navBreak) {
      margin-bottom: 68px;
    }

    @include customMedia($navBreak) {
      margin-bottom: 33px;
    }

    @include medium() {
      padding: 0 42px;
    }

    @include small() {
      padding: 0;
    }
  }

  .top__weather {
    width: 300px;
    height: 180px;
    position: absolute;
    top: -30px;
    right: 0;

    @include medium() {
      display: none;
    }
  }
}

.heroNext {
  width: 100%;
  @include borderBox();

  @include customLarge($medium-break) {
    padding: 0 218px 0;
    margin-top: 83px;
  }

  @include medium() {
    text-align: center;
    margin-top: 52px;
  }

  .cardo--30 {
    margin-bottom: 25px;
  }
}

.heroNext__cta {
  color: $pictonBlue;
  display: inline-block;
  text-align: center;
  cursor: pointer;

  @include customLarge($medium-break) {
    margin-bottom: 78px;
  }

  @include medium() {
    margin-bottom: 40px;
  }

  .zmdi {
    font-size: 40px;
  }
}

.servicesBanner {
  background-color: $pictonBlue;
  width: 100%;

  @include small() {
    padding: 40px 0 40px;
  }

  @include large() {
    padding: 170px 0 130px;
  }
}

.twoBoxes {
  @include customLarge($medium-break) {
    padding: 86px 0 80px;
  }

  @include medium() {
    padding: 23px 0 29px;
  }
}

.twoBoxesBox__container {
  border: 2px solid $mystic;
}

.twoBoxesBox,
.twoBoxesBox__header,
.twoBoxesBox__text {
  @include borderBox();
}

.twoBoxesBox {
  float: left;

  @include customLarge($medium-break) {
    width: 50%;
    padding: 0 38px 0;
  }

  @include medium() {
    width: 100%;
    padding: 0 $gutterMobile 0;
  }

  &--mortgage {

    .twoBoxesBox__header {
      color: $white;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      background-image: url(../imgs/mortgage-bg-2x.jpg);
    }

    a {
      color: $orange;
    }

    .underlinedLink {
      @include customLarge($medium-break) {
        margin: 22px 0 0 40px;
      }

      @include medium() {
        margin: 22px 0 34px 0;
      }
    }
  }

  &--services {
    .twoBoxesBox__header {
      @include small() {
        padding: 26px;
      }
    }

    .openSans--B14 {
      color: $coldBurst;
    }
  }
}

.twoBoxesBox__header {
  position: relative;

  @include customLarge($medium-break) {
    padding: 21px 40px 42px;
  }

  @include medium() {
    padding: 26px 26px 85px;
  }

  a {
    z-index: 2;
    display: block;
    position: inherit;
  }
}

.twoBoxesBox__overlay {
  @extend .serviceBox__overlay;
}

.twoBoxesBox__text {
  border-top: 2px solid $mystic;

  @include customLarge($medium-break) {
    padding: 30px 40px 35px;
  }

  @include medium() {
    padding: 21px 20px 15px;
  }

  .openSans--R14 {
    margin-bottom: 24px;
  }
}

.twoBoxesBox__icon {
  margin-bottom: 4px;
}

.teamPhoto {
  width: 100%;
  height: auto;

  @include large() {
    margin-top: 70px;
  }

  @include small() {
    margin-top: 40px;
  }

  img {
    width: 100%;
  }
}

.teamPhoto__text {
  max-width: 850px;

  @include large() {
    width: 75%;
    margin-left: auto;
    margin-right: auto;
  }

  @include small() {
    width: 100%;
    @include borderBox();
    padding: 0 $gutterMobile;
  }

  a {
    color: $pictonBlue;
  }

  span {
    color: $coldBurst;
  }
}

.logosSection {
  overflow: hidden;

  @include customLarge($medium-break) {
    padding: 60px 0 143px 0;
  }

  @include medium() {
    padding: 20px 0;
  }

  .tickerLogos {
    width: 300px;
    overflow: hidden;
  }

  .tickerLogo {
    display: inline-block;
    float: left;
    text-align: center;
  }

  img {
    margin: 0 34px;
    width: 100%;
    max-width: 300px;
  }
}
.logoSection__heading {
  padding: 20px;
  color: #293239;
  text-align: center;
  @include customLarge($medium-break) {
    margin-bottom: 60px;
    min-height: 60px;
  }
}
.containerWidth.testimonials {
  position: relative;

  @include customMedia($medium-break) {
    padding: 0 18px;
    box-sizing: border-box;
  }
}