/////////////////////////////////////
// Boxes ////////////////////////////
/////////////////////////////////////

.messageBoxContainer {
  text-align: center;

  @include large() {
    padding: 0 $gutter;
  }

  @include medium() {
    padding: 0 $gutterMobile;
  }
}

.messageBox {
  background-color: $mystic;
  display: inline-block;
  padding: 30px 45px;
  border-top: 9px solid $pictonBlue;

  @include large() {
    margin-bottom: 70px;
  }

  @include small() {
    margin-bottom: 50px;
  }

  .zmdi {
    font-size: 30px;
    color: $pictonBlue;
    transform: translateY(2px);
    margin-right: 10px;
  }

  .cardo--24 {
    color: $haiti;
  }
}