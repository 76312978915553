$magicNumber: 50px;

.servicesBanner.services {
  min-height: 180px;
  position: relative;
}
.servicesBanner__banner {
  width: calc(100% - 40px);
  margin-left: auto;
  margin-right: auto;
  margin-top: 180px;
  max-width: 1000px;
  .text {
    font-size: 15px;
    text-align: center;
    color: #fff;
    strong {
      color: #000;
    }
  }
  @include small() {
    margin-top: 40px;
  }
}


.servicesStep02, .servicesStep03 {
  display: none;
}

.servicesStep02 {
  @include large() {
    .servicesSlide__overlay {
      width: 60%;
    }
  }

  @include customMedia(1200px) {
    .servicesSlide__overlay {
      width: 98%;
      box-sizing: border-box;
    }
  }

  @include customMedia(816px) {
    .servicesSlide__overlay {
      font-size: 38px;
    }
  }

  @include small() {
    .servicesSlide__overlay {
      font-size: 18px;
      top: 50%;
    }
  }
}

.servicesStep {
  color: white;

  @include small() {
    width: 100%;
    margin-bottom: 0;
  }

  @include large() {
    width: 50px;
    margin-left: 20px;
    position: absolute;
    z-index: 1;

    &.initialPos {
      transform: translateY(0px);
    }

    &.secondPos {
      transform: translateY(-60px);
    }

    &.thirdPos {
      transform: translateY(-120px);
    }
  }

  @include animate();

  &__step {
    width: $magicNumber;
    height: $magicNumber;
    margin-bottom: 10px;
    line-height: $magicNumber;
    cursor: pointer;

    @include animate();

    @include small() {
      display: inline-block;
    }

    &.current {
      border-radius: 100%;
      background-color: white;
      color: $pictonBlue;
    }
  }
}

.servicesMobileHint {
  color: $white;

  @include large() {
    display: none;
  }

  @include small() {
    display: block;
    margin-bottom: 40px;
  }
}

.servicesPhrase, .optionsBoxes {
  @include borderBox();

  @include small() {
    width: 90%;
    margin: 0 auto;
  }

  @include large() {
    width: 66%;
    margin: 0 auto;
  }

  @include customLarge(1180px) {
    width: 81%;
    padding-left: 218px;
    margin: 0;
  }
}

.servicesPhrase {
  line-height: 64px;
  color: $white;
  transform: translateY(-10px);

  @include large() {
    &.cardo--50 {
      font-size: 46px;
    }
  }

  @include customMedia(1024px) {
    padding: 0 70px;
  }

  @include small() {
    padding: 0;
  }

  .selected {
    position: relative;
    color: $black;
    cursor: pointer;
    transform: translateY(0);
    @include animate(transform);

    @include small() {
      @include linkUnderliner(-3px, $lineWidth: 7px, $lineLength: 100%, $lineColor: $white);
    }

    @include large() {
      @include linkUnderliner(-7px, $lineWidth: 13px, $lineLength: 100%, $lineColor: $white);

      &:hover {
        transform: translateY(-20px);
      }
    }

    &__tooltip {
      position: absolute;
      top: 100px;
      width: 150%;
      text-align: center;
      margin-left: -75%;
      color: white;

      @include customMedia(1264px) {
        display: none;
      }
    }

    i {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: -22px;
      font-size: 32px;
    }
  }
}

.servicesSlide {
  position: relative;
  @include animate();

  &.out {
    transform: translate3d(0, -30%, 0);
  }

  &.in {
    transform: translate3d(0, 0, 0);
  }

  &__overlay {
    background-color: white;
    position: absolute;
    top: 36%;
    left: 50%;
    transform: translate3d(-50%, -70%, 0);
    opacity: 0;
    pointer-events: none;
    z-index: 500;
    transition: all 0.8s cubic-bezier(0.68, -0.55, 0.27, 1.55);

    &.active {
      transform: translate3d(-50%, -50%, 0);
      opacity: 1;
      pointer-events: all;
    }

    @include small() {
      padding: 30px 30px 20px;
      max-width: 90%;
      min-width: 200px;
    }

    @include large() {
      padding: 40px 40px 30px;
    }

    li {
      margin-bottom: 6px;
      padding-right: 60px;
      position: relative;
      cursor: pointer;
      @include animate(color);

      @include small() {
        line-height: 24px;
        margin-bottom: 16px;
      }

      span {
        opacity: 0;
        position: absolute;
        top: 0;
        right: 0;
      }

      &:hover {
        color: $pictonBlue;
      }
    }

    li.selected {
      color: $pictonBlue;

      span {
        opacity: 1;
      }
    }
  }

  .nextButton {
    width: 12%;
    min-width: 110px;
    margin-right: 20px;
    text-align: center;
    background-color: white;
    border-radius: 4px;
    transform: translateY(-8px);
    cursor: pointer;
    @include borderBox();

    @include small() {
      height: 44px;
      margin: 30px auto 0;
      font-size: 16px;
      line-height: 44px;
    }

    @include large() {
      height: 54px;
      position: absolute;
      right: 0;
      top: 0;
      line-height: 54px;
    }

    .zmdi {
      margin-left: 4%;

      @include small() {
        font-size: 16px;
        transform: translateY(2px);
      }

      @include large() {
        font-size: 28px;
        transform: translateY(5px);
      }
    }
  }

  .optionsHint {
    margin-top: 20px;

    @include small() {
      font-size: 12px;
    }

    &Icon {
      font-size: 32px;

      @include small() {
        display: none;
      }

      @include large() {
        margin-top: -16px;
      }
    }
  }

  .optionsBoxes {
    &__container {
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      align-items: stretch;

      @include small() {
        width: 100%;
      }

      @include large() {
        width: 80%;
      }
    }

    &__box {
      display: flex;
      justify-content: center;
      width: 44%;
      margin: 3%;
      font-size: 18px;
      text-align: center;
      border: 1px solid white;
      color: white;
      border-radius: 4px;
      cursor: pointer;
      float: left;
      @include animate();
      @include borderBox();

      @include large() {
        padding: 40px 10px;
      }

      @include customMedia(868px) {
        font-size: 15px;
      }

      @include small() {
        width: 100%;
        padding: 20px 10px;
        margin: 4% auto;
      }

      &:hover, &.selected {
        color: $pictonBlue;
        background-color: white;
      }

      body.firefox & {
        margin-bottom: 20px;
      }
    }
  }
}

.services {
  .selectionsModal {
    top: -67px;

    &.complete {
      @include large() {
        top: -83px;
      }
    }
  }
}

.selectionsModal {
  max-width: 666px;
  min-height: 82px;
  position: absolute;
  top: -130px;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
  background-color: white;
  box-shadow: 0px 11px 25px 2px rgba(0, 0, 0, 0.1);
  display: none;
  opacity: 1;
  pointer-events: all;
  @include animate();

  @include small() {
    width: 94%;
  }

  @include large() {
    width: 90%;
  }

  &.cardo--24 {
    @include small() {
      font-size: 16px;
      line-height: 16px;
    }
  }

  &.inactive {
    opacity: 0;
    pointer-events: none;
    transform: translate3d(-50%, -30%, 0);
  }

  &.complete {
    @include large() {
      top: -210px;
    }

    @include small() {
      top: -126px;
    }
  }

  &:after {
    content: '';
    position: absolute;
    left: 50%;
    bottom: -14px;
    width: 0;
    height: 0;
    border-left: 14px solid transparent;
    border-right: 14px solid transparent;
    border-top: 14px solid white;
    transform: translate3d(-50%, 0, 0);
    clear: both;
  }

  &__step {
    display: none;
    cursor: pointer;
    opacity: 0;
    @include animate();

    @include small() {
      padding: 14px;
    }

    @include large() {
      padding: 20px;
    }

    &.active {
      opacity: 1;
    }

    &:nth-child(2) {
      border-top: 1px solid $concrete;
    }
  }

  .zmdi {
    font-size: 32px;
    transform: translateY(6px);
    margin-left: 10px;

    @include small() {
      line-height: 14px;
    }
  }
}

.servicesBanner[data-selection*="early"], .servicesBanner[data-selection*="none"] {
  .optionsBoxes:not(.earlyStages) {
    display: none;
  }
}

.servicesBanner[data-selection*="10"] {
  .optionsBoxes:not(.tenYears) {
    display: none;
  }
}

.servicesBanner[data-selection*="20"] {
  .optionsBoxes:not(.twentyYears) {
    display: none;
  }
}

.servicesBanner[data-selection*="planning"] {
  .optionsBoxes:not(.planningToRetire) {
    display: none;
  }
}

.servicesBanner[data-selection*="retired"] {
  .optionsBoxes:not(.retired) {
    display: none;
  }
}