.teamBlocks {
  @include flexSquaresContainer();
}

.teamBlock {
  float: left;
  cursor: pointer;
  @include borderBox();
  @include animate(opacity);

  @include large() {
    @include flexSquaresItemWidth(25%);
    max-width: 300px;
    padding: 2%;
  }

  @include medium() {
    @include flexSquaresItemWidth($oneThird);
    max-width: 300px;
    padding: 2%;
  }

  @include small() {
    @include flexSquaresItemWidth(50%);
    padding: 2%;
  }

  .aboutModal & {
    @include large() {
      width: 90%;
      max-width: 520px;
      padding: 0;
      margin: 0 auto;
      float: initial;
    }

    @include small() {
      width: 90%;
      max-width: 90%;
      float: none;
      margin: 0 auto;
    }
  }

  &__image {
    width: 100%;
    padding-bottom: 100%;
    background-position: center;
    background-size: 100%;
    border-radius: 100%;

    @include large() {
      margin-bottom: 18px;
    }

    @include small() {
      margin-bottom: 16px;
    }

    .aboutModal & {
      @include large() {
        width: 300px;
        padding-bottom: 300px;
        margin: 60px auto 20px;
      }

      @include small() {
        width: 50%;
        padding-bottom: 50%;
        margin: 20px auto;
      }
    }
  }

  &__name {
    margin-bottom: -4px;

    &.cardo--32  {
      @include small() {
        font-size: 18px;
        line-height: 25px;
        margin-bottom: 2px;
      }
    }
  }

  &__title {
    .aboutModal & {
      margin-bottom: 20px;
    }
  }

  &__title, &__readMore {
    &.openSans--R20 {
      @include small() {
        font-size: 12px;
        line-height: 18px;
      }
    }
  }

  &__description {
    margin-bottom: 40px;

    &.openSans--R20 {
      .aboutModal & {
        @include small() {
          font-size: 13px;
        }
      }
    }
  }

  &__linkedIn {
    margin-bottom: 40px;
    color: $haiti;

    i {
      margin-bottom: 6px;
      display: block;
      font-size: 22px;
    }
  }

  &__document {
    margin-top: 20px;
    font-size: 16px;
    display: block;
    color: $haiti;
  }
}