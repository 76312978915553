body.explorer {
	.weather__icon {
		margin-top: 0;
		height: 20px;
	}

	.weather__city {
		margin-bottom: 10px;
	}

	.servicesPhrase {
		.selected__tooltip {
			margin-left: -132%;
		}
	}

	.newsletter.threeItemsSelector {
		.threeItemsSelector__item {
			background-position: 4px center;
		}

		.threeItemsSelector__item:nth-child(1),
		.threeItemsSelector__item:nth-child(2) {
			background-size: 104px;
		}

		.threeItemsSelector__item:nth-child(3) {
			background-size: 106px;
		}
	}

	.newsDownload__img {
		border: none;
		outline: 0;
	}

	.footer .fpa-logo {
		height: 36px;
	}

	.animatedSliderComponent {
		.controls__right {
			margin-left: 3px;

			.zmdi {
				line-height: 36px;
			}
		}

		.controls__left {
			.zmdi {
				line-height: 36px;
			}
		}
	}

	.timeline .modal .teamBlock,
	.aboutModal .teamBlock {
		float: inherit;
	}
}