/////////////////////////////////////
// Services /////////////////////////
/////////////////////////////////////

$gutterServiceBox: 15px;


.servicesTiles {

  @include large() {
    padding-top: 70px;
  }

  @include small() {
    padding-top: $gutterMobile;
  }

  &WithSelector {
    @include large() {
      padding-top: 250px;
    }

    &.onlyCat {
      @include large() {
        padding-top: 180px;
      }
    }
  }

  .servicesHeading,
  .servicesSubHeading {
    text-align: center;

    @include outerGutters();
  }

  .servicesHeading {
    color: $paleSky;
    margin-bottom: 6px;
  }

  .servicesSubHeading {
    color: $paleSky;

    @include large() {
      margin-bottom: 60px;
    }

    @include small() {
      font-size: 13px;
      margin-bottom: 29px;
    }
  }
}

.serviceBox__container {
  border-style: solid;
  border-width: 2px;
  border-color: $mystic;
  @include animate();

  @include customLarge($medium-break) {
    min-height: 380px;
  }
}

.serviceBox,
.serviceBox__headerText,
.serviceBox__text {
  @include borderBox();
}

.serviceBox__headerText {
  @include customMedia(1268px) {
    .cardo--40 {
      font-size: 32px;
      line-height: 40px;
    }
  }
}

.servicesBoxes {
  color: $paleSky;

  @include large() {
    margin-bottom: 50px;
  }

  @include small() {
    margin-bottom: 0;
  }

  @include customLarge($medium-break) {
    padding: 0 ($gutter - $gutterServiceBox);
  }
}

.serviceBox {
  float: left;
  text-align: left;
  transform: translate3d(0, 0, 0);

  &:hover, &.open {
    .serviceBox__text {
      border-top-color: transparent;
      box-shadow: 0px 0px 18px 3px rgba(0,0,0,0.13);
    }

    .serviceBox__container {
      border-color: transparent;
    }

  }

  @include customLarge($medium-break) {
    width: 24%;
    padding: $gutterServiceBox;
  }

  @include medium() {
    width: 100%;
    padding: 0 $gutterMobile $gutterMobile;
  }

  a {
    color: $paleSky;
    @include animate();

    &:hover {
      color: $pictonBlue;
    }
  }

  .choosenTagsContainer {
    width: 100%;
    padding-right: 20px;
    @include borderBox();
  }

  .serviceDescription {
    @include customLarge($medium-break) {
      min-height: 48px;
    }
    @include animate();

    &.expanded {
      padding-right: 20px;
    }

    &.expanded, &.initial {
      opacity: 0;
      pointer-events: none;
      display: none;

      &.show {
        display: block;
      }

      &.active {
        opacity: 1;
        pointer-events: all;
      }
    }
  }

  .choosenTagsExpanded {
    display: none;
  }
}

.serviceBox__header {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: $white;
  position: relative;
  overflow: hidden;

  @include customLarge($medium-break) {
    min-height: 244px;
  }

  @include medium() {
    min-height: 175px;
  }
}

.serviceBox__headerText {
  z-index: 2;
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 28px 25px 60px;
  @include borderBox();
}

.serviceBox__overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: .2;
  top: 0;
  left: 0;
  z-index: 1;
}

.serviceBox__text {
  background-color: $white;
  border-top-style: solid;
  border-top-width: 2px;
  border-top-color: $mystic;
  position: relative;

  @include customLarge($medium-break) {
    padding: 20px 25px 25px;
    min-height: 170px;
  }

  @include customMedia(1270px) {
    padding: 20px 18px 25px;
  }

  @include medium() {
    padding: 20px 25px 25px;
  }

  .openSans--B14 {
    @include customMedia(1038px) {
      font-size: 12px;
      line-height: 18px;
    }

    @include small() {
      font-size: 14px;
      line-height: 24px;
    }
  }

  .openSans--R14 {
    margin-bottom: 24px;

    @include customMedia(1038px) {
      font-size: 12px;
      line-height: 18px;
    }

    @include small() {
      font-size: 14px;
      line-height: 24px;
    }
  }

  .zmdi-plus-circle, .zmdi-minus-circle {
    position: absolute;
    font-size: 30px;
    color: $pictonBlue;
    bottom: 13px;
    right: 13px;
    cursor: pointer;
  }
}

.serviceBox__icon {
  margin-bottom: 4px;
  max-width: 55px;
}

.servicesCustomiserContainer {
  padding-top: 100px;
}

.servicesCustomiser {
  text-align: center;
  @include borderBox();

  @include large() {
    margin-bottom: 125px;
    padding: 0 $gutter;
  }

  @include medium() {
    margin-bottom: 80px;
    padding: 0 $gutter;
  }

  @include small() {
    padding: 0 $gutterMobile;
    margin-bottom: 45px;
  }

  .underlinedLink {
    color: $coldBurst;
  }
}

.serviceSearch {
  text-align: center;

  @include large() {
    margin-bottom: 130px;
  }

  @include small() {
    margin-bottom: 42px;
  }

  @include outerGutters();

  .cardo--32 {
    color: $haiti;
    display: inline-block;
    margin-right: 25px;

    @include medium() {
      margin-bottom: 25px;
    }

    @include small() {
      font-size: 20px;
    }
  }
}

.input--servicesSearch {
  width: 25%;
  font-size: 30px;
  position: relative;

  @include medium() {
    width: 100%;
    min-width: 240px;
  }

  .input--services {
    @include medium() {
      width: 100%;
    }
  }

  .zmdi-search {
    position: absolute;
    right: 8px;
    bottom: 0px;
  }
}

.servicesHero {
  opacity: 1;
  max-height: 1000px;
  @include animate();

  &.hide {
    opacity: 0;
  }

  .heroNext {
    @include customLarge($medium-break){
      padding: 0 162px;
    }

    @include large() {
      div.openSans--R20 {
        text-align: left;
      }
    }

    @include small() {
      margin-top: 22px;

      div.openSans--R20 {
        text-align: center;
      }
    }
  }
}

.servicesSearchHeading {
  .selectionsModal__step, .selectionsModal {
    display: block;
  }

  .selectionsModal {
    top: 180px;
  }
}

.servicesTiles {
  .servicesSearchHeading {
    .selectionsModal__step, .selectionsModal {
      cursor: default;
    }
  }
}

.servicesOthers {
  background-color: $mystic;

  @include large() {
    padding: 112px 0 48px;
  }

  @include small() {
    padding: 62px 0 48px;
  }
}

.servicesOthersHeading {
  text-align: center;
  color: $haiti;

  @include large() {
    margin-bottom: 60px;
  }

  @include small() {
    margin-bottom: 30px;
  }
}

.recommendedServices {
  &.servicesTiles {
    @include small() {
      padding-top: 190px;
    }
  }

  .servicesSearchHeading .selectionsModal {

    @include customMedia($navBreak) {
      top: 120px;
    }

    @include small() {
      top: 90px;
    }
  }
}