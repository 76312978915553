/////////////////////////////////////
// Breakpoints //////////////////////
/////////////////////////////////////
@import '../vars/index';

@mixin small() {
  @media screen and (max-width: $small-break) {
    @content;
  }
}

@mixin medium() {
  @media screen and (max-width: $medium-break) {
    @content;
  }
}

@mixin large() {
  @media screen and (min-width: $small-break) {
    @content;
  }
}

@mixin customLarge($custom) {
  @media screen and (min-width: $custom) {
    @content;
  }
}

@mixin customMedia($custom) {
  @media screen and (max-width: $custom) {
    @content;
  }
}