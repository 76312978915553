/////////////////////////////////////
// Quote ////////////////////////////
/////////////////////////////////////

.quote {
  width: 100%;

  img {
    width: 100%
  }

  &__thumb {
    width: 74%;
    max-width: 280px;
    margin: 32px auto;
    border-radius: 100%;
    overflow: hidden;
  }

  &__position {
    margin-bottom: 20px;
  }

  &__text {
    width: 74%;
    margin: 0 auto;
    color: $pictonBlue;
  }
}