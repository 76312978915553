/////////////////////////////////////
// Fonts Declaration ////////////////
/////////////////////////////////////

// Styles

.sackers--14 {
  font-family: $sackers;
  font-size: 14px;
  line-height: 24px;
  font-weight: normal;
  font-style: normal;
}

.sackers--19 {
  font-family: $sackers;
  font-size: 19px;
  line-height: 24px;
  font-weight: normal;
  font-style: normal;
}

.cardo--53 {
  font-family: $cardo;

  @include large() {
    font-size: 53px;
    line-height: 60px;
  }

  @include small() {
    font-size: 30px;
    line-height: 40px;
  }
}

.cardo--50 {
  font-family: $cardo;

  @include large() {
    font-size: 50px;
    line-height: 59px;
  }

  @include small() {
    font-size: 30px;
    line-height: 36px;
  }
}

.cardo--40 {
  font-family: $cardo;

  @include large() {
    font-size: 40px;
    line-height: 50px;
  }

  @include small() {
    font-size: 30px;
    line-height: 35px;
  }
}

.cardo--32 {
  font-family: $cardo;

  @include large() {
    font-size: 32px;
    line-height: 43px;
  }

  @include medium() {
    font-size: 28px;
  }

  @include small() {
    font-size: 24px;
    line-height: 35px;
  }
}

.cardo--30 {
  font-family: $cardo;

  @include large() {
    font-size: 30px;
    line-height: 43px;
  }

  @include small() {
    font-size: 24px;
    line-height: 35px;
  }
}

.cardo--24 {
  font-family: $cardo;

  @include large() {
    font-size: 24px;
    line-height: 43px;
  }

  @include medium() {
    font-size: 18px;
  }

  @include small() {
    font-size: 18px;
    line-height: 32px;
  }
}

.openSans--R30 {
  font-family: $openSans;
  font-weight: $regular;
  font-size: 30px;
  line-height: 60px;
}

.openSans--R24 {
  font-family: $openSans;
  font-weight: $regular;

  @include large() {
    font-size: 24px;
    line-height: 28px;
  }

  @include small() {
    font-size: 18px;
    line-height: 32px;
  }
}

.openSans--R20 {
  font-family: $openSans;
  font-weight: $regular;

  @include large() {
    font-size: 20px;
    line-height: 36px;
  }

  @include small() {
    font-size: 16px;
    line-height: 25px;
  }
}

.openSans--SB20 {
  font-family: $openSans;
  font-weight: $semiBold;
  font-size: 20px;
  line-height: 31px;
}

.openSans--R20 {
  font-family: $openSans;
  font-weight: $regular;
  font-size: 20px;
  line-height: 32px;
}

.openSans--R19 {
  font-family: $openSans;
  font-weight: $regular;
  font-size: 19px;
  line-height: 32px;
}

.openSans--R18 {
  font-family: $openSans;
  font-weight: $regular;
  font-size: 18px;
  line-height: 25px;
}

.openSans--R16 {
  font-family: $openSans;
  font-weight: $regular;
  font-size: 16px;
  line-height: 23px;
}

.openSans--SB16 {
  font-family: $openSans;
  font-weight: $semiBold;
  font-size: 16px;
  line-height: 23px;
}

.openSans--SB19 {
  font-family: $openSans;
  font-weight: $semiBold;
  font-size: 19px;
  line-height: 32px;
}

.openSans--R14 {
  font-family: $openSans;
  font-weight: $regular;
  font-size: 14px;
  line-height: 24px;
}

.openSans--B14 {
  font-family: $openSans;
  font-weight: $bold;
  font-size: 14px;
  line-height: 24px;
}

.openSans--R12 {
  font-family: $openSans;
  font-weight: $regular;
  font-size: 12px;
  line-height: 18px;
}


.underlinedLink {
  font-family: $cardo;
  font-size: 32px;
  line-height: 43px;
  @include linkUnderliner(-7px, $lineWidth: 13px, $lineLength: 100%, $lineColor: $mystic);

  @include large() {
    font-size: 32px;
    line-height: 43px;
  }

  @include small() {
    font-size: 20px;
    line-height: 43px;
  }
}