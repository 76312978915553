/////////////////////////////////////
// Testimonials /////////////////////
/////////////////////////////////////

.testimonialSlider {
  @include medium() {
    padding: 0 $gutterMobile;
  }
}

.testimonialSlide__imgContainer {
  width: 100%;
  position: relative;
}

.testimonialSlide__img {
  display: block;

  @include customLarge($medium-break) {
    width: 75%;
    margin-left: auto;
    margin-right: auto;
  }

  @include medium() {
    width: 100%;
  }
}

.testimonialSlide__arrows {
  position: absolute;
  top: 50%;
  transform: translateY(-250%);
  z-index: 2;

  @include customLarge($medium-break) {
    width: 100%;
    left: 0;
  }

  @include customMedia($medium-break) {
    width: 94%;
    left: 3%;
  }

  @include small() {
    top: 58px;
    transform: translateY(0);
  }

  .zmdi {
    color: $mystic;
    font-size: 40px;
    cursor: pointer;
    @include animate(color);

    &:hover {
      color: $malachite;
    }
  }

  .testimonialSlide__arrowPrev {
    float: left;

    @include customLarge($medium-break) {
      margin-left: 60px;
    }

    @include medium() {
      margin-left: 12px;
    }
  }

  .testimonialSlide__arrowNext {
    float: right;

    @include customLarge($medium-break) {
      margin-right: 60px;
    }

    @include medium() {
      margin-right: 12px;
    }
  }
}

.testimonialSlide__box {
  @include boxify($malachite);
  @include borderBox();

  position: relative;

  @include customLarge($medium-break) {
    padding: 36px 40px 44px;
    width: 50%;
    margin: -80px auto 0;
  }

  @include medium() {
    padding: 20px 20px 25px;
    width: 100%;
    margin: 0;
  }

  .cardo--32 {
    margin-bottom: 12px;
  }
}