.spinner {
  width: 40px;
  height: 40px;
  margin: 100px auto;
  background-color: #FFF;

  display: none;

  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 500;
  margin-top: -20px;
  margin-left: -20px;

  border-radius: 100%;
  -webkit-animation: sk-scaleout 1.0s infinite ease-in-out;
  animation: sk-scaleout 1.0s infinite ease-in-out;
}

@-webkit-keyframes sk-scaleout {
  0% { -webkit-transform: scale(0) }
  100% {
    -webkit-transform: scale(1.0);
    opacity: 0;
  }
}

@keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  } 100% {
      -webkit-transform: scale(1.0);
      transform: scale(1.0);
      opacity: 0;
    }
}