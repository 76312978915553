/*-------------------------------------------------------*\
    Mixins
/*-------------------------------------------------------*/

/*	Column width
-------------------------------------------------------*/ 

@mixin columns($columns, $omega:false, $float:left) {

    float: $float;
    width: column($columns);

      @if $omega == omega {
         margin-right: 0;
      }

      @else {
         margin-right: percentage($gutter-width, $grid-width);
      }
  
}

/*	Column push
-------------------------------------------------------*/
@mixin push($columns) {
    $margin: column($columns) + percentage($gutter-width, $grid-width);
    margin-left: $margin;
}

/*	Column push right
-------------------------------------------------------*/
@mixin pushRight($columns) {
  $margin: column($columns) + percentage($gutter-width, $grid-width);
  margin-right: $margin;
}

/* Margin Replicate
-------------------------------------------------------*/
@mixin margin($margin:bottom) {
  margin-#{$margin}: percentage($gutter-width, $grid-width);
}

/*	Clearfix
-------------------------------------------------------*/ 

@mixin clearfix() {

	&:before,
	&:after {
	    content: "";
	    display: table;
	}
	
	&:after {
	    clear: both;
	}

}

/*	Breakpoints
-------------------------------------------------------*/ 

@mixin break-at($point) {

	@media (min-width: $point)  {
        @content;
	}

}