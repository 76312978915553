/////////////////////////////////////
// Documents ////////////////////////
/////////////////////////////////////

.kabelDocuments {
  @include large() {
    padding: 106px 30px 66px;
  }

  @include small() {
    padding: 45px 18px 0 18px;
  }

  @include borderBox();

  h1 {
    @include large() {
      margin-bottom: 88px;
    }

    @include small() {
      margin-bottom: 40px;
    }
  }
}

.newsletterDownloads {
  @include large() {
    padding: 0 30px 106px 30px;
  }

  @include small() {
    padding: 0 18px 45px;
  }

  h1 {
    margin-bottom: 40px;
  }
}

.ajaxContainer {
  position: relative;
  min-height: 300px;

  h1, .newsletterList {
    opacity: 1;
    @include animate(opacity);
  }

  &.transitioning {
    h1, .newsletterList {
      opacity: 0;
    }
  }
}

.loadingSpinner,
.loadingSpinner:after {
  border-radius: 100%;
  width: 32px;
  height: 32px;
}

.loadingSpinner {
  margin: 0;
  font-size: 10px;
  text-indent: -9999em;
  border-top: 4px solid rgba(31, 176, 240, .2);
  border-right: 4px solid rgba(31, 176, 240, .2);
  border-bottom: 4px solid rgba(31, 176, 240, .2);
  border-left: 4px solid $pictonBlue;
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  animation: load8 1.1s infinite linear;
  position: absolute;
  display: none;
}
@-webkit-keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}