.weather {
  &__top, &__bottom {
    position: absolute;
    bottom: 0;
    color: white;
  }

  &__top {
    width: 186px;
    height: 180px;
    left: 0;
    font-family: $openSans;
    font-weight: $light;
    background-color: $pictonBlue;

    div {
      width: 100%;
      text-align: center;
    }
  }

  &__bottom {
    width: 130px;
    height: 164px;
    padding-top: 10px;
    padding-left: 10px;
    right: 0;
    font-family: $openSans;
    font-weight: $semiBold;
    background-color: $haiti;
    @include borderBox();

    &:before {
      content: '';
      position: absolute;
      top: -16px;
      left: 0;
      width: 0;
      height: 0;
      border-top: 16px solid transparent;
      border-bottom: 0 solid transparent;
      border-right: 16px solid $mystic;
    }
  }
}

.weather__bottom {
  .weather__summary {
    line-height: 22px;
    margin-top: 12px;
  }
}

.weather__summary {
  line-height: 48px;
  padding-right: 7px;
}

.weather__temperature {
  margin-bottom: 10px;
  font-size: 70px;
  line-height: 57px;
  @include borderBox();
}

.weather__apparentTemperature {
  font-size: 24px;
  line-height: 32px;
  opacity: .5;
  @include borderBox();
}

.weather__city {
  color: $pictonBlue;
}

.weather__icon {
  width: 30px;
  margin-top: 16px;

  path {
    fill: #FFF;
  }
}