/////////////////////////////////////
// Slider ///////////////////////////
/////////////////////////////////////

.sliderSlide {
  position: relative;

  img {
    width: 100%;
    float: left;
  }
}

.slideInfo {
  width: 100%;
  position: relative;
  float: left;

  @include customLarge($medium-break) {
    margin-top: -160px;
  }

  @include medium() {
    margin-top: 0;
  }
}

.sliderSelectorContainer {

  @include customLarge($medium-break) {
    width: 40%;
    float: left;
    text-align: center;
  }

  @include medium() {
    position: absolute;
    text-align: left;
    top: 36px;
    left: 16px;
  }
}

.SlideSelector {
  display: inline-block;

  @include customLarge($medium-break) {
    margin-top: 188px;
  }

  @include medium() {
    margin-top: 0;
  }

  &--item {
    background-color: $pictonBlue;
    border-radius: 100%;
    float: left;

    @include customLarge($medium-break) {
      width: 18px;
      height: 18px;
      margin: 0 10px;
    }

    @include medium() {
      width: 10px;
      height: 10px;
      margin: 0 6px;
    }

    &.current {
      background-color: $coldBurst;
    }
  }
}

.slidePanel {
  float: left;
  @include borderBox();

  @include customLarge($medium-break) {
    width: 60%;
    padding-right: 42px;
  }

  @include medium() {
    width: 100%;
  }

  &.large {
    float: right;

    @include customLarge($medium-break) {
      width: 63%;
    }
  }
}

.slidePanel__box {
  @include boxify($coldBurst);
  @include borderBox();
  background-color: $white;

  @include customLarge($medium-break) {
    padding: 60px 65px;
  }

  @include medium() {
    padding: 50px 20px 25px;
  }

  &.light {
    @include boxify($pictonBlue);
  }

  .cardo--53 {

    @include customLarge($medium-break) {
      margin-bottom: 13px;
    }

    @include medium() {
      margin-bottom: 20px;
    }
  }
}