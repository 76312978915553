/////////////////////////////////////
// Core Styles //////////////////////
/////////////////////////////////////

body {
  font-family: $openSans;
  font-style: normal;
  color: $paleSky;

  font-size: 14px;
  letter-spacing: 0.17px;
  line-height: 18px;

  -webkit-font-smoothing: antialiased;

  background-color: #fff;
  opacity: 1;

  transition: transform .8s ease-in-out, opacity .8s ease-in-out;

  a {
    text-decoration: none;
  }

  &.hide {
    transform: translate3d(0, 2%, 0);
    opacity: 0;
    pointer-events: none;
  }
}

.wrapper {
  width: 100%;
  @include borderBox();

  @include customLarge($navBreak) {
    padding-top: 125px;
  }

  @include customMedia($navBreak) {
    padding-top: 75px;
  }
}

.containerWidth {
  max-width: 1286px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;

  &--small {
    max-width: 1058px;
  }

  strong {
    font-weight: normal;
    color: $pictonBlue;
  }
}

.windowOverlay, .privacyOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .6);
  opacity: 0;
  pointer-events: none;
  @include animate();

  &.active {
    pointer-events: all;
    display: block;
    opacity: 1;
  }
}

.privacyOverlay {
  z-index: 300;
}

.windowOverlay {
  z-index: 400;
  display: none;
}

.twoThirdCol {
  @include large() {
    @include columns(8);
  }

  @include medium() {
    width: 100%;
  }
}

.oneThirdCol {
  @include large() {
    @include columns(4, omega);
  }

  @include medium() {
    width: 100%;
  }
}

.sectionPadding {
  padding: 0 30px;
}