/////////////////////////////////////
// Includes /////////////////////////
/////////////////////////////////////

// Styles

.navContainer {
  position: fixed;
  width: 100%;
  z-index: 200;
  backface-visibility: hidden;
}

.nav__item {
  @extend .sackers--19;
}

.navBar {
  background-color: $white;
  overflow: hidden;
  position: relative;

  @include customLarge($navBreak) {
    padding-top: 37px;
  }

  @include customMedia($navBreak) {
    padding-top: 0;
  }

  &__logo {
    display: inline-block;
    float: left;
    height: auto;
    border-bottom-style: solid;
    border-bottom-color: $mystic;

    @include customLarge($navBreak) {
      width: 195px;
      padding: 27px 22px 25px;
      border-bottom-width: 3px;
    }

    @include customMedia($navBreak) {
      width: 100%;
      padding: 23px 21px 21px;
      border-bottom-width: 2px;
    }

    img {
      height: auto;

      @include customLarge($navBreak) {
        width: 100%;
      }

      @include customMedia($navBreak) {
        width: 170px;
      }

    }
  }

  .nav__item.active a {
    border-bottom-color: $coldBurst;
  }

  .nav__item a {
    padding: 25px 15px;
    border-bottom-style: solid;
    border-bottom-color: $mystic;
    border-bottom-width: 3px;
    @include animate(border-bottom-color);

    &:hover {
      border-bottom-color: $coldBurst;
    }
  }

  .nav {
    margin-top: 11px;
    display: inline-block;
    float: left;

    @include customMedia($navBreak) {
      display: none;
    }
  }
}

.nav__item,
.navRight {
  display: inline-block;

  a {
    display: inline-block;
    text-decoration: none;
    color: $paleSky;
  }
}

.nav__item {
  float: left;
}

.navRight {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 10;

  a {
    border-bottom-style: solid;
    border-bottom-color: transparent;
    border-bottom-width: 3px;
    @include animate(border-bottom-color);

    @include small() {
      padding: 4px 16px 0;
    }

    @include large() {
      padding: 8px 16px;

      &:hover {
        border-bottom-color: $pictonBlue;
      }
    }
  }

  .documents {
    @include customMedia($navBreak) {
      display: none;
    }
  }

  .zmdi {
    margin-right: 8px;
  }
}

#nav--mobile,
#mobileHamburgerIcon {
  @include customLarge($navBreak) {
    display: none;
  }

  @include customMedia($navBreak) {
    display: inline-block;
  }
}

#mobileHamburgerIcon {
  font-size: 21px;
  color: $coldBurst;
  cursor: pointer;
  opacity: 1;
  pointer-events: all;
  transform: rotate(0deg);

  &.open {
    opacity: 0;
    pointer-events: none;
  }

  .navBar & {
    position: absolute;
    top: 18px;
    right: 8px;
    padding: 10px;
    z-index: 10;
  }
}

#nav--mobile {
  opacity: 0;
  pointer-events: none;
  width: 100%;
  height: 100%;
  padding: 30px;
  position: fixed;
  z-index: 200;
  background-color: $white;
  transform: translate3d(0, 0, 0);
  @include borderBox();
  @include animate(opacity);

  .menu__list {
    width: 100%;
    display: block;
    margin-top: 54px;
    text-align: center;
  }

  .nav__item {
    display: block;
    float: none;
    @extend .openSans--R16;
  }

  .nav__item__link {
    padding: 18px 0;
    display: block;
  }

  &.open {
    opacity: 1;
    pointer-events: all;
  }
}

#mobileCloseIcon {
  cursor: pointer;
  padding: 13px 20px;
  position: absolute;
  right: -2px;
  top: 12px;
  font-size: 26px;
  color: $coldBurst;
}

.documents {
  float: right;
}