.translatedContent {
  @include small() {
    width: 80%;
    margin-left: 10%;
    margin-bottom: 60px;
  }

  @include large() {
    width: 66%;
    margin-left: 17%;
  }
}

.animatedSliderComponent {
  width: auto;
  overflow: hidden;
  position: relative;

  ul {
    width: 100%;
  }

  .bx-viewport {
    overflow: visible!important;
  }

  @include large() {
    margin-bottom: 80px;
  }

  @include small() {
    overflow: hidden;
  }

  &__item {
    width: 70%;
  }

  &__animator {
    opacity: 1;
    //@include animate(opacity);

    &.faded {
      opacity: .3;
    }
  }

  .item {
    display: inline-block;
    float: left;

    &__image {
      img {
        width: 100%;
      }

      @include small() {
        width: 90%;
        margin-left: 5%;
        margin-bottom: 20px;
      }

      @include large() {
        width: 52%;
        float: right;
      }
    }

    &__copy {
      @include borderBox();

      @include small() {
        width: 90%;
        margin-left: 5%;
      }

      @include large() {
        width: 48%;
        padding: 0 26px;
        float: left;
      }
    }

    &__title {
      margin-bottom: 20px;
    }
  }

  &__controls {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
  }

  .controls {
    @include borderBox();

    @include small() {
      padding: 0 10px;
    }

    @include large() {
      padding: 0 26px;
    }

    div {
      width: 34px;
      height: 34px;
      display: inline-block;
      font-size: 32px;
      border-radius: 100%;
      background-color: $paleSky;
      cursor: pointer;
      @include animate();

      &:hover {
        background-color: $pictonBlue;
      }
    }

    &__right {
      margin-left: 10px;
      float: right;

      i {
        margin-left: 4px;
      }
    }

    &__left
    i {
      margin-left: -2px;
    }
  }
}