/*-------------------------------------------------------*\
    Functions
/*-------------------------------------------------------*/

//calc grid width
$grid-width: $columns * $column-width + ($columns - 1) * $gutter-width;

// Calc percentage
@function percentage($size, $width) {
  @return $size / $width * 100%;
}

// Calc column width
@function column($value) {
  $width: $value * $column-width + ($value - 1) * $gutter-width;
  @return percentage($width, $grid-width);
}