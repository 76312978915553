/////////////////////////////////////
// Boxes ////////////////////////////
/////////////////////////////////////

.kDocs {
  &__item {
    float: left;

    @include large() {
      width: 50%;
      margin-bottom: 4%;
    }

    @include small() {
      width: 100%;
      margin-bottom: 40px;
    }
  }

  &__thumb {
    width: 32%;
    float: left;
    position: relative;

    img {
      width: 100%;
    }

    &:hover {
      .kDocs__hover {
        opacity: 1;
      }
    }
  }

  &__hover {
    position: absolute;
    opacity: 0;
    @include animate(opacity);
    top: 0;
    left: 0;
  }

  &__data {
    width: 54%;
    margin-left: 8%;
    padding-right: 6%;
    float: left;
  }

  &__title, &__downloadLink {
    color: $coldBurst;
  }

  &__title {
    margin-bottom: 2px;
    letter-spacing: 1px;

    @include small() {
      margin-bottom: 0;
    }

    &.sackers--19 {
      @include small() {
        font-size: 15px;
        line-height: 20px;
      }
    }
  }

  &__downloadLink {
    display: block;
    letter-spacing: 0;

    &.openSans--R19 {
      @include small() {
        font-size: 13px;
        line-height: 20px;
      }
    }
  }

  &__subTitle {
    margin-bottom: 18px;

    @include small() {
      margin-bottom: 10px;
    }
  }

  &__description {
    &.openSans--R14 {
      @include small() {
        font-size: 13px;
        line-height: 20px;
      }
    }
  }
}