/////////////////////////////////////
// Contact //////////////////////////
/////////////////////////////////////

.contact {
  @include large() {
    padding: 106px 30px;
  }

  @include small() {
    padding: 46px 30px;
  }

  h1 {
    color: $pictonBlue;

    @include large() {
      margin-bottom: 60px;
    }

    @include small() {
      margin-bottom: 35px;
    }
  }
}

.contactBox {
  width: 100%;
  float: left;

  @include customLarge($medium-break) {
    max-width: 304px;
  }

  background-color: $mystic;

  &__top, &__bottom {
    padding: 24px 20px 6px;
    @include borderBox();

    a {
      display: block;
    }
  }

  &__top {
    p {
      margin-bottom: 20px;
    }

    a {
      color: $haiti;
      letter-spacing: -.5px;
    }
  }

  &__bottom {
    background-color: $haiti;

    a {
      margin-bottom: 20px;
    }

    .mailTo {
      @include animate(color);
    }

    .mailTo:hover {
      color: white;
    }
  }

  i {
    @include large() {
      margin-right: 8px;
    }
  }

  &__phone {
    margin-bottom: 20px;
  }
}