/////////////////////////////////////
// Buttons //////////////////////////
/////////////////////////////////////

.btn {
  display: inline-block;
  margin-bottom: 0;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-image: none;
  border-radius: 8px;
  text-decoration: none;
  @include animate();
  @include borderBox();
}

.btn--default {
  font-family: $openSans;
  font-weight: $regular;
  line-height: 1;

  @include large() {
    padding: 32px 50px;
    font-size: 30px;
  }

  @include small() {
    font-size: 23px;
    padding: 31px 38px;
  }
}

.btn--cyan {
  color: $white;
  background-color: $pictonBlue;
  box-shadow: 0px 5px 0px 0px $coldBurst;

  &:hover {
    background-color: $coldBurst;
    box-shadow: 0px 5px 0px 0px $haiti;
  }
}

.btn--blue {
  color: $white;
  background-color: $coldBurst;
  box-shadow: 0px 5px 0px 0px $haiti;

  &:hover {
    color: $coldBurst;
    background-color: $white;
    box-shadow: 0px 5px 0px 0px $coldBurst;
  }
}

.downloadButton {
  color: $pictonBlue;
  cursor: pointer;

  .zmdi {
    font-size: 26px;
  }

  .zmdi,
  .span {
    display: block;
  }
}