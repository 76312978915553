/////////////////////////////////////
// Colours //////////////////////////
/////////////////////////////////////

.paleSky {
  color: $paleSky;
}

.coldBurst {
  color: $coldBurst;
}

.haiti {
  color: $haiti;
}

.mystic {
  color: $mystic;
}

.pictonBlue {
  color: $pictonBlue;
}

.white {
  color: $white;
}

.black {
  color: $black;
}

.orange {
  color: $orange;
}

.malachite {
  color: $malachite;
}

.outerSpace {
  color: $outerSpace;
}

.pineGlade {
  color: $pineGlade;
}

.fuelYellow {
  color: $fuelYellow;
}

.concrete {
  color: $concrete;
}