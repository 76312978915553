/*-------------------------------------------------------*\
    Variables
/*-------------------------------------------------------*/
/*	Grid
--------------------------------------------------------*/
/*-------------------------------------------------------*\
    Functions
/*-------------------------------------------------------*/
/*-------------------------------------------------------*\
    Mixins
/*-------------------------------------------------------*/
/*	Column width
-------------------------------------------------------*/
/*	Column push
-------------------------------------------------------*/
/*	Column push right
-------------------------------------------------------*/
/* Margin Replicate
-------------------------------------------------------*/
/*	Clearfix
-------------------------------------------------------*/
/*	Breakpoints
-------------------------------------------------------*/
/*-------------------------------------------------------*\
    Grid
/*-------------------------------------------------------*/
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

body {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  color: #667682;
  font-size: 14px;
  letter-spacing: 0.17px;
  line-height: 18px;
  -webkit-font-smoothing: antialiased;
  background-color: #fff;
  opacity: 1;
  transition: transform .8s ease-in-out, opacity .8s ease-in-out;
}

body a {
  text-decoration: none;
}

body.hide {
  transform: translate3d(0, 2%, 0);
  opacity: 0;
  pointer-events: none;
}

.wrapper {
  width: 100%;
  box-sizing: border-box;
}

@media screen and (min-width: 996px) {
  .wrapper {
    padding-top: 125px;
  }
}

@media screen and (max-width: 996px) {
  .wrapper {
    padding-top: 75px;
  }
}

.containerWidth {
  max-width: 1286px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.containerWidth--small {
  max-width: 1058px;
}

.containerWidth strong {
  font-weight: normal;
  color: #1fb0f0;
}

.windowOverlay, .privacyOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s ease-in-out;
}

.windowOverlay.active, .privacyOverlay.active {
  pointer-events: all;
  display: block;
  opacity: 1;
}

.privacyOverlay {
  z-index: 300;
}

.windowOverlay {
  z-index: 400;
  display: none;
}

@media screen and (min-width: 640px) {
  .twoThirdCol {
    float: left;
    width: 65.811965812%;
    margin-right: 2.5641025641%;
  }
}

@media screen and (max-width: 1024px) {
  .twoThirdCol {
    width: 100%;
  }
}

@media screen and (min-width: 640px) {
  .oneThirdCol {
    float: left;
    width: 31.6239316239%;
    margin-right: 0;
  }
}

@media screen and (max-width: 1024px) {
  .oneThirdCol {
    width: 100%;
  }
}

.sectionPadding {
  padding: 0 30px;
}

.hidden {
  display: none;
}

.block {
  display: block;
}

.flex {
  display: flex;
}

.inline {
  display: inline;
}

.inline-block {
  display: inline-block;
}

.inline-flex {
  display: inline-flex;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-scroll {
  overflow: scroll;
}

.overflow-auto {
  overflow: auto;
}

.pre {
  white-space: pre;
}

.nowrap {
  white-space: nowrap;
}

.scroll-horizontal {
  overflow-x: scroll;
  overflow-y: hidden;
}

.scroll-vertical {
  overflow-x: hidden;
  overflow-y: scroll;
}

.no-scrollbar::-webkit-scrollbar {
  width: 0px;
  height: 0px;
  background: transparent;
}

.resize-none {
  resize: none;
}

.resize-vertical {
  resize: vertical;
}

.resize-horizontal {
  resize: horizontal;
}

.no-pointer-events {
  pointer-events: none;
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: none;
}

.clearfix:before, .clearfix:after {
  content: " ";
  display: table;
}

.clearfix:after {
  clear: both;
}

.blue {
  color: #222461;
}

.ta-l {
  text-align: left;
}

.ta-c {
  text-align: center;
}

.ta-r {
  text-align: right;
}

.tt-u {
  text-transform: uppercase;
}

.mb-40 {
  margin-bottom: 40px;
}

.btn {
  display: inline-block;
  margin-bottom: 0;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-image: none;
  border-radius: 8px;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  box-sizing: border-box;
}

.btn--default {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  line-height: 1;
}

@media screen and (min-width: 640px) {
  .btn--default {
    padding: 32px 50px;
    font-size: 30px;
  }
}

@media screen and (max-width: 640px) {
  .btn--default {
    font-size: 23px;
    padding: 31px 38px;
  }
}

.btn--cyan {
  color: #fff;
  background-color: #1fb0f0;
  box-shadow: 0px 5px 0px 0px #222461;
}

.btn--cyan:hover {
  background-color: #222461;
  box-shadow: 0px 5px 0px 0px #0c1830;
}

.btn--blue {
  color: #fff;
  background-color: #222461;
  box-shadow: 0px 5px 0px 0px #0c1830;
}

.btn--blue:hover {
  color: #222461;
  background-color: #fff;
  box-shadow: 0px 5px 0px 0px #222461;
}

.downloadButton {
  color: #1fb0f0;
  cursor: pointer;
}

.downloadButton .zmdi {
  font-size: 26px;
}

.downloadButton .zmdi,
.downloadButton .span {
  display: block;
}

input, select, textarea, button {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  box-sizing: border-box;
  border-image: none;
  -webkit-appearance: none;
  border: 0;
}

input:focus, select:focus, textarea:focus, button:focus {
  box-shadow: none;
  outline: 0 none;
}

button {
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

input.input--white {
  font-size: 16px;
  height: 55px;
  padding: 0 12px;
  border-radius: 4px;
  border-width: 0;
  background-color: #fff;
  color: #293239;
}

input.input--white::-webkit-input-placeholder {
  color: #293239 !important;
}

input.input--services {
  font-size: 20px;
  border-bottom-width: 4px;
  border-bottom-color: #0c1830;
  border-bottom-style: solid;
  padding: 0 0 10px;
}

@media screen and (min-width: 1024px) {
  input.input--services {
    min-width: 460px;
  }
}

@media screen and (max-width: 1024px) {
  input.input--services {
    min-width: 240px;
  }
}

input.input--services::-webkit-input-placeholder {
  color: #667682 !important;
}

.submit--green {
  font-weight: 600;
  font-size: 16px;
  height: 55px;
  padding: 0 33px;
  border-radius: 4px;
  border-width: 0;
  background-color: #16d772;
  color: #fff;
}

.submit--green:hover {
  background-color: #fff;
  color: #16d772;
}

.kabelForm {
  margin-bottom: 56px;
  transition: all 0.3s ease-in-out;
}

.kabelForm .textField, .kabelForm textarea {
  width: 100%;
}

.kabelForm .textField {
  height: 54px;
}

.kabelForm .textField.error + label {
  color: red;
}

.kabelForm textarea {
  padding-top: 22px;
}

.kabelForm .inputBox.field {
  margin-bottom: 50px;
  position: relative;
  float: left;
}

@media screen and (min-width: 640px) {
  .kabelForm .inputBox.field {
    width: 46%;
  }
  .kabelForm .inputBox.field:nth-child(even) {
    margin-left: 4%;
  }
}

@media screen and (max-width: 640px) {
  .kabelForm .inputBox.field {
    width: 100%;
  }
}

.kabelForm .inputBox.text {
  margin-bottom: 50px;
  position: relative;
  float: left;
}

@media screen and (min-width: 640px) {
  .kabelForm .inputBox.text {
    width: 96%;
  }
}

@media screen and (max-width: 640px) {
  .kabelForm .inputBox.text {
    width: 100%;
  }
}

.kabelForm .inputBox.submit {
  width: 96%;
  float: left;
}

.kabelForm .inputBox.text:after, .kabelForm .inputBox.field:after {
  content: '';
  width: 100%;
  height: 1px;
  position: absolute;
  bottom: -1px;
  left: 0;
  background-color: #dee6e9;
  z-index: 2;
  transition: all 0.3s ease-in-out;
}

.kabelForm .inputBox.active:after {
  content: '';
  width: 100%;
  height: 3px;
  position: absolute;
  bottom: -3px;
  left: 0;
  background-color: #1fb0f0;
  z-index: 2;
}

.kabelForm .inputBox label {
  position: absolute;
  z-index: 3;
  left: 0;
  top: 0;
  transform: translate3d(0, 50%, 0);
  color: #0c1830;
  transition: all 0.3s ease-in-out;
}

.kabelForm .inputBox.active label {
  transform: translate3d(0, -50%, 0);
  color: #1fb0f0;
  font-size: 13px;
}

.sliderSlide {
  position: relative;
}

.sliderSlide img {
  width: 100%;
  float: left;
}

.slideInfo {
  width: 100%;
  position: relative;
  float: left;
}

@media screen and (min-width: 1024px) {
  .slideInfo {
    margin-top: -160px;
  }
}

@media screen and (max-width: 1024px) {
  .slideInfo {
    margin-top: 0;
  }
}

@media screen and (min-width: 1024px) {
  .sliderSelectorContainer {
    width: 40%;
    float: left;
    text-align: center;
  }
}

@media screen and (max-width: 1024px) {
  .sliderSelectorContainer {
    position: absolute;
    text-align: left;
    top: 36px;
    left: 16px;
  }
}

.SlideSelector {
  display: inline-block;
}

@media screen and (min-width: 1024px) {
  .SlideSelector {
    margin-top: 188px;
  }
}

@media screen and (max-width: 1024px) {
  .SlideSelector {
    margin-top: 0;
  }
}

.SlideSelector--item {
  background-color: #1fb0f0;
  border-radius: 100%;
  float: left;
}

@media screen and (min-width: 1024px) {
  .SlideSelector--item {
    width: 18px;
    height: 18px;
    margin: 0 10px;
  }
}

@media screen and (max-width: 1024px) {
  .SlideSelector--item {
    width: 10px;
    height: 10px;
    margin: 0 6px;
  }
}

.SlideSelector--item.current {
  background-color: #222461;
}

.slidePanel {
  float: left;
  box-sizing: border-box;
}

@media screen and (min-width: 1024px) {
  .slidePanel {
    width: 60%;
    padding-right: 42px;
  }
}

@media screen and (max-width: 1024px) {
  .slidePanel {
    width: 100%;
  }
}

.slidePanel.large {
  float: right;
}

@media screen and (min-width: 1024px) {
  .slidePanel.large {
    width: 63%;
  }
}

.slidePanel__box {
  border-style: solid;
  border-width: 1px;
  border-color: #000;
  border-top-width: 17px;
  border-top-color: #222461;
  background-color: #fff;
  box-sizing: border-box;
  background-color: #fff;
}

@media screen and (min-width: 1024px) {
  .slidePanel__box {
    padding: 60px 65px;
  }
}

@media screen and (max-width: 1024px) {
  .slidePanel__box {
    padding: 50px 20px 25px;
  }
}

.slidePanel__box.light {
  border-style: solid;
  border-width: 1px;
  border-color: #000;
  border-top-width: 17px;
  border-top-color: #1fb0f0;
  background-color: #fff;
}

@media screen and (min-width: 1024px) {
  .slidePanel__box .cardo--53 {
    margin-bottom: 13px;
  }
}

@media screen and (max-width: 1024px) {
  .slidePanel__box .cardo--53 {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 1024px) {
  .testimonialSlider {
    padding: 0 18px;
  }
}

.testimonialSlide__imgContainer {
  width: 100%;
  position: relative;
}

.testimonialSlide__img {
  display: block;
}

@media screen and (min-width: 1024px) {
  .testimonialSlide__img {
    width: 75%;
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (max-width: 1024px) {
  .testimonialSlide__img {
    width: 100%;
  }
}

.testimonialSlide__arrows {
  position: absolute;
  top: 50%;
  transform: translateY(-250%);
  z-index: 2;
}

@media screen and (min-width: 1024px) {
  .testimonialSlide__arrows {
    width: 100%;
    left: 0;
  }
}

@media screen and (max-width: 1024px) {
  .testimonialSlide__arrows {
    width: 94%;
    left: 3%;
  }
}

@media screen and (max-width: 640px) {
  .testimonialSlide__arrows {
    top: 58px;
    transform: translateY(0);
  }
}

.testimonialSlide__arrows .zmdi {
  color: #dee6e9;
  font-size: 40px;
  cursor: pointer;
  transition: color 0.3s ease-in-out;
}

.testimonialSlide__arrows .zmdi:hover {
  color: #16d772;
}

.testimonialSlide__arrows .testimonialSlide__arrowPrev {
  float: left;
}

@media screen and (min-width: 1024px) {
  .testimonialSlide__arrows .testimonialSlide__arrowPrev {
    margin-left: 60px;
  }
}

@media screen and (max-width: 1024px) {
  .testimonialSlide__arrows .testimonialSlide__arrowPrev {
    margin-left: 12px;
  }
}

.testimonialSlide__arrows .testimonialSlide__arrowNext {
  float: right;
}

@media screen and (min-width: 1024px) {
  .testimonialSlide__arrows .testimonialSlide__arrowNext {
    margin-right: 60px;
  }
}

@media screen and (max-width: 1024px) {
  .testimonialSlide__arrows .testimonialSlide__arrowNext {
    margin-right: 12px;
  }
}

.testimonialSlide__box {
  border-style: solid;
  border-width: 1px;
  border-color: #000;
  border-top-width: 17px;
  border-top-color: #16d772;
  background-color: #fff;
  box-sizing: border-box;
  position: relative;
}

@media screen and (min-width: 1024px) {
  .testimonialSlide__box {
    padding: 36px 40px 44px;
    width: 50%;
    margin: -80px auto 0;
  }
}

@media screen and (max-width: 1024px) {
  .testimonialSlide__box {
    padding: 20px 20px 25px;
    width: 100%;
    margin: 0;
  }
}

.testimonialSlide__box .cardo--32 {
  margin-bottom: 12px;
}

.newsletters {
  background-color: #293239;
  color: #fff;
  text-align: center;
}

@media screen and (min-width: 640px) {
  .newsletters {
    padding: 83px 30px;
  }
}

@media screen and (max-width: 640px) {
  .newsletters {
    padding: 30px 18px;
  }
}

@media screen and (min-width: 640px) {
  .newsletters .cardo--53 {
    margin-bottom: 94px;
  }
}

@media screen and (max-width: 640px) {
  .newsletters .cardo--53 {
    margin-bottom: 28px;
  }
}

.newsletters .input--white {
  margin-right: 10px;
}

@media screen and (max-width: 640px) {
  .newsletters .input--white {
    margin-bottom: 10px;
    width: 100%;
  }
}

@media screen and (min-width: 640px) {
  .newsBoxes {
    margin-bottom: 78px;
  }
}

@media screen and (max-width: 640px) {
  .newsBoxes {
    margin-bottom: 40px;
  }
}

.newsDownload {
  box-sizing: border-box;
  float: left;
  width: 33.3333333333%;
}

@media screen and (min-width: 780px) {
  .newsDownload {
    width: 33.3333333333%;
    padding: 0 40px;
  }
}

@media screen and (max-width: 780px) {
  .newsDownload {
    width: 100%;
    padding: 0 18px;
  }
}

@media screen and (max-width: 780px) {
  .newsDownload:nth-child(2), .newsDownload:nth-child(3) {
    display: none;
  }
}

.newsDownload__img {
  margin-bottom: 32px;
  border: 1px solid #dee6e9;
  width: 100%;
  max-width: 244px;
  margin-left: auto;
  margin-right: auto;
  transform: translateY(0);
  transition: all 0.3s ease-in-out;
}

.newsDownload__img:hover {
  transform: translateY(-30px);
}

.newsDownload__img img {
  border: 1px solid #dee6e9;
  max-width: 100%;
  width: 100%;
  float: left;
}

.newsletterSubscription {
  margin-bottom: 35px;
  min-height: 55px;
  transition: all 0.3s ease-in-out;
}

@media screen and (min-width: 860px) {
  #newsMail {
    width: 37.5%;
    max-width: 284px;
  }
}

@media screen and (min-width: 860px) {
  #newsFirstName,
  #newsLastName {
    width: 18.75%;
    max-width: 146px;
  }
}

@media screen and (min-width: 860px) {
  #newsSubmit {
    width: 25%;
    max-width: 167px;
  }
}

@media screen and (max-width: 860px) {
  #newsMail,
  #newsFirstName,
  #newsLastName {
    width: 80%;
    margin-bottom: 18px;
  }
}

@media screen and (max-width: 640px) {
  #newsMail,
  #newsFirstName,
  #newsLastName {
    width: 100%;
    margin-bottom: 18px;
  }
}

.contactUs, .introText {
  box-sizing: border-box;
}

@media screen and (min-width: 640px) {
  .contactUs, .introText {
    padding: 102px 30px;
  }
}

@media screen and (min-width: 640px) {
  .contactUs .cardo--53, .introText .cardo--53 {
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 640px) {
  .contactUs .cardo--53, .introText .cardo--53 {
    margin-bottom: 27px;
  }
}

.contactUs--white, .introText--white {
  background-color: #fff;
}

.contactUs--white .cardo--53, .introText--white .cardo--53 {
  color: #1fb0f0;
}

.contactUs--cyan, .introText--cyan {
  background-color: #1fb0f0;
}

.contactUs--cyan .cardo--53, .introText--cyan .cardo--53 {
  color: #fff;
}

.contactUs--outerSpace, .introText--outerSpace {
  background-color: #293239;
}

.contactUs--outerSpace .cardo--53, .introText--outerSpace .cardo--53 {
  color: #fff;
}

.contactUs--concrete, .introText--concrete {
  background-color: #F2F2F2;
}

.contactUs--concrete .cardo--53, .introText--concrete .cardo--53 {
  color: #667682;
}

.contactUs--concrete a, .contactUs--concrete strong, .introText--concrete a, .introText--concrete strong {
  color: #16d772;
}

.contactUs--concrete strong, .introText--concrete strong {
  font-weight: normal;
}

.contactUs--concrete i, .introText--concrete i {
  margin-left: 6px;
}

@media screen and (max-width: 640px) {
  .contactUs {
    padding: 43px 18px 64px;
  }
}

@media screen and (max-width: 640px) {
  .introText {
    padding: 43px 18px 26px;
  }
}

.messageBoxContainer {
  text-align: center;
}

@media screen and (min-width: 640px) {
  .messageBoxContainer {
    padding: 0 23px;
  }
}

@media screen and (max-width: 1024px) {
  .messageBoxContainer {
    padding: 0 18px;
  }
}

.messageBox {
  background-color: #dee6e9;
  display: inline-block;
  padding: 30px 45px;
  border-top: 9px solid #1fb0f0;
}

@media screen and (min-width: 640px) {
  .messageBox {
    margin-bottom: 70px;
  }
}

@media screen and (max-width: 640px) {
  .messageBox {
    margin-bottom: 50px;
  }
}

.messageBox .zmdi {
  font-size: 30px;
  color: #1fb0f0;
  transform: translateY(2px);
  margin-right: 10px;
}

.messageBox .cardo--24 {
  color: #0c1830;
}

.kDocs__item {
  float: left;
}

@media screen and (min-width: 640px) {
  .kDocs__item {
    width: 50%;
    margin-bottom: 4%;
  }
}

@media screen and (max-width: 640px) {
  .kDocs__item {
    width: 100%;
    margin-bottom: 40px;
  }
}

.kDocs__thumb {
  width: 32%;
  float: left;
  position: relative;
}

.kDocs__thumb img {
  width: 100%;
}

.kDocs__thumb:hover .kDocs__hover {
  opacity: 1;
}

.kDocs__hover {
  position: absolute;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  top: 0;
  left: 0;
}

.kDocs__data {
  width: 54%;
  margin-left: 8%;
  padding-right: 6%;
  float: left;
}

.kDocs__title, .kDocs__downloadLink {
  color: #222461;
}

.kDocs__title {
  margin-bottom: 2px;
  letter-spacing: 1px;
}

@media screen and (max-width: 640px) {
  .kDocs__title {
    margin-bottom: 0;
  }
}

@media screen and (max-width: 640px) {
  .kDocs__title.sackers--19, .kDocs__title.nav__item {
    font-size: 15px;
    line-height: 20px;
  }
}

.kDocs__downloadLink {
  display: block;
  letter-spacing: 0;
}

@media screen and (max-width: 640px) {
  .kDocs__downloadLink.openSans--R19 {
    font-size: 13px;
    line-height: 20px;
  }
}

.kDocs__subTitle {
  margin-bottom: 18px;
}

@media screen and (max-width: 640px) {
  .kDocs__subTitle {
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 640px) {
  .kDocs__description.openSans--R14 {
    font-size: 13px;
    line-height: 20px;
  }
}

.threeItemsSelector__item {
  padding: 20px 20px 20px 94px;
  float: left;
  background-repeat: no-repeat;
  background-position: 34px center;
  box-sizing: border-box;
  cursor: pointer;
  position: relative;
}

@media screen and (min-width: 640px) {
  .threeItemsSelector__item {
    border-top: 2px solid #dee6e9;
    border-bottom: 2px solid #dee6e9;
  }
}

@media screen and (max-width: 640px) {
  .threeItemsSelector__item {
    border-left: 2px solid #dee6e9;
    border-right: 2px solid #dee6e9;
  }
}

.threeItemsSelector__item:nth-child(3) {
  border-right: 2px solid #dee6e9;
}

.threeItemsSelector__item:before {
  content: '';
  position: absolute;
  transition: all 0.3s ease-in-out;
  border: 2px solid #dee6e9;
}

@media screen and (min-width: 640px) {
  .threeItemsSelector__item:before {
    width: calc(100% - 2px);
    height: 100%;
    top: -2px;
    left: 0px;
  }
}

@media screen and (max-width: 640px) {
  .threeItemsSelector__item:before {
    width: 100%;
    height: calc(100% - 2px);
    top: 0px;
    left: -2px;
  }
}

@media screen and (min-width: 640px) {
  .threeItemsSelector__item {
    width: 33.3333333333%;
  }
}

@media screen and (max-width: 640px) {
  .threeItemsSelector__item {
    width: 100%;
  }
}

.threeItemsSelector__item:hover {
  z-index: 2;
}

.threeItemsSelector {
  min-height: 88px;
  margin-bottom: 64px;
  position: relative;
}

.threeItemsSelector.newsletter .threeItemsSelector__item:nth-child(1) {
  background-image: url("../imgs/kabel-connect-ico.svg");
  background-size: 34px;
}

.threeItemsSelector.newsletter .threeItemsSelector__item:nth-child(1):hover:before {
  border: 2px solid #1fb0f0;
}

.threeItemsSelector.newsletter .threeItemsSelector__item:nth-child(2) {
  background-image: url("../imgs/kabel-news-flash-ico.svg");
  background-size: 34px;
}

.threeItemsSelector.newsletter .threeItemsSelector__item:nth-child(2):hover:before {
  border: 2px solid #f0a41f;
}

.threeItemsSelector.newsletter .threeItemsSelector__item:nth-child(3) {
  background-image: url("../imgs/lifestyle-newsletter-ico.svg");
  background-size: 26px;
}

.threeItemsSelector.newsletter .threeItemsSelector__item:nth-child(3):hover:before {
  border: 2px solid #B8C895;
}

.threeItemsSelector.about .threeItemsSelector__item:nth-child(1) {
  background-image: url("../imgs/kabel-overview-ico.svg");
  background-size: 30px;
}

.threeItemsSelector.about .threeItemsSelector__item:nth-child(1):hover:before {
  border: 2px solid #1fb0f0;
}

.threeItemsSelector.about .threeItemsSelector__item:nth-child(2) {
  background-image: url("../imgs/kabel-history-ico.svg");
  background-size: 18px;
}

.threeItemsSelector.about .threeItemsSelector__item:nth-child(2):hover:before {
  border: 2px solid #16d772;
}

.threeItemsSelector.about .threeItemsSelector__item:nth-child(3) {
  background-image: url("../imgs/kabel-team-ico.svg");
  background-size: 33px;
}

.threeItemsSelector.about .threeItemsSelector__item:nth-child(3):hover:before {
  border: 2px solid #222461;
}

body.explorer .threeItemsSelector.about .threeItemsSelector__item:nth-child(1) {
  background-image: url("../imgs/kabel-overview-ico.svg");
  background-size: 35px;
}

.newsletterList {
  width: 100%;
}

@media screen and (min-width: 640px) {
  .newsletterList {
    margin-bottom: 38px;
  }
}

.newsletterList__item {
  padding: 14px;
  border-top: 2px solid #dee6e9;
  box-sizing: border-box;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

.newsletterList__item:hover {
  background-color: #0c1830;
}

.newsletterList__item:hover .itemTitle {
  color: #fff;
}

.newsletterList .itemTitle {
  transition: all 0.3s ease-in-out;
}

.newsletterList .itemDate {
  color: #B8C895;
}

.quote {
  width: 100%;
}

.quote img {
  width: 100%;
}

.quote__thumb {
  width: 74%;
  max-width: 280px;
  margin: 32px auto;
  border-radius: 100%;
  overflow: hidden;
}

.quote__position {
  margin-bottom: 20px;
}

.quote__text {
  width: 74%;
  margin: 0 auto;
  color: #1fb0f0;
}

.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 640px) {
  .translatedContent {
    width: 80%;
    margin-left: 10%;
    margin-bottom: 60px;
  }
}

@media screen and (min-width: 640px) {
  .translatedContent {
    width: 66%;
    margin-left: 17%;
  }
}

.animatedSliderComponent {
  width: auto;
  overflow: hidden;
  position: relative;
}

.animatedSliderComponent ul {
  width: 100%;
}

.animatedSliderComponent .bx-viewport {
  overflow: visible !important;
}

@media screen and (min-width: 640px) {
  .animatedSliderComponent {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 640px) {
  .animatedSliderComponent {
    overflow: hidden;
  }
}

.animatedSliderComponent__item {
  width: 70%;
}

.animatedSliderComponent__animator {
  opacity: 1;
}

.animatedSliderComponent__animator.faded {
  opacity: .3;
}

.animatedSliderComponent .item {
  display: inline-block;
  float: left;
}

.animatedSliderComponent .item__image img {
  width: 100%;
}

@media screen and (max-width: 640px) {
  .animatedSliderComponent .item__image {
    width: 90%;
    margin-left: 5%;
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 640px) {
  .animatedSliderComponent .item__image {
    width: 52%;
    float: right;
  }
}

.animatedSliderComponent .item__copy {
  box-sizing: border-box;
}

@media screen and (max-width: 640px) {
  .animatedSliderComponent .item__copy {
    width: 90%;
    margin-left: 5%;
  }
}

@media screen and (min-width: 640px) {
  .animatedSliderComponent .item__copy {
    width: 48%;
    padding: 0 26px;
    float: left;
  }
}

.animatedSliderComponent .item__title {
  margin-bottom: 20px;
}

.animatedSliderComponent__controls {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
}

.animatedSliderComponent .controls {
  box-sizing: border-box;
}

@media screen and (max-width: 640px) {
  .animatedSliderComponent .controls {
    padding: 0 10px;
  }
}

@media screen and (min-width: 640px) {
  .animatedSliderComponent .controls {
    padding: 0 26px;
  }
}

.animatedSliderComponent .controls div {
  width: 34px;
  height: 34px;
  display: inline-block;
  font-size: 32px;
  border-radius: 100%;
  background-color: #667682;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.animatedSliderComponent .controls div:hover {
  background-color: #1fb0f0;
}

.animatedSliderComponent .controls__right {
  margin-left: 10px;
  float: right;
}

.animatedSliderComponent .controls__right i {
  margin-left: 4px;
}

.animatedSliderComponent .controls__left i {
  margin-left: -2px;
}

.teamBlocks {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: flex-start;
}

.teamBlock {
  float: left;
  cursor: pointer;
  box-sizing: border-box;
  transition: opacity 0.3s ease-in-out;
}

@media screen and (min-width: 640px) {
  .teamBlock {
    width: 25%;
    max-width: 25%;
    flex: 1 0 24%;
    max-width: 300px;
    padding: 2%;
  }
}

@media screen and (max-width: 1024px) {
  .teamBlock {
    width: 33.3333333333%;
    max-width: 33.3333333333%;
    flex: 1 0 32.3333333333%;
    max-width: 300px;
    padding: 2%;
  }
}

@media screen and (max-width: 640px) {
  .teamBlock {
    width: 50%;
    max-width: 50%;
    flex: 1 0 49%;
    padding: 2%;
  }
}

@media screen and (min-width: 640px) {
  .aboutModal .teamBlock {
    width: 90%;
    max-width: 520px;
    padding: 0;
    margin: 0 auto;
    float: initial;
  }
}

@media screen and (max-width: 640px) {
  .aboutModal .teamBlock {
    width: 90%;
    max-width: 90%;
    float: none;
    margin: 0 auto;
  }
}

.teamBlock__image {
  width: 100%;
  padding-bottom: 100%;
  background-position: center;
  background-size: 100%;
  border-radius: 100%;
}

@media screen and (min-width: 640px) {
  .teamBlock__image {
    margin-bottom: 18px;
  }
}

@media screen and (max-width: 640px) {
  .teamBlock__image {
    margin-bottom: 16px;
  }
}

@media screen and (min-width: 640px) {
  .aboutModal .teamBlock__image {
    width: 300px;
    padding-bottom: 300px;
    margin: 60px auto 20px;
  }
}

@media screen and (max-width: 640px) {
  .aboutModal .teamBlock__image {
    width: 50%;
    padding-bottom: 50%;
    margin: 20px auto;
  }
}

.teamBlock__name {
  margin-bottom: -4px;
}

@media screen and (max-width: 640px) {
  .teamBlock__name.cardo--32 {
    font-size: 18px;
    line-height: 25px;
    margin-bottom: 2px;
  }
}

.aboutModal .teamBlock__title {
  margin-bottom: 20px;
}

@media screen and (max-width: 640px) {
  .teamBlock__title.openSans--R20, .teamBlock__readMore.openSans--R20 {
    font-size: 12px;
    line-height: 18px;
  }
}

.teamBlock__description {
  margin-bottom: 40px;
}

@media screen and (max-width: 640px) {
  .aboutModal .teamBlock__description.openSans--R20 {
    font-size: 13px;
  }
}

.teamBlock__linkedIn {
  margin-bottom: 40px;
  color: #0c1830;
}

.teamBlock__linkedIn i {
  margin-bottom: 6px;
  display: block;
  font-size: 22px;
}

.teamBlock__document {
  margin-top: 20px;
  font-size: 16px;
  display: block;
  color: #0c1830;
}

.modal {
  width: 100%;
  max-width: 732px;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 400;
  transform: translate3d(-50%, -50%, 0);
  background-color: #fff;
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s ease-in-out;
}

.modal__close {
  padding: 20px;
  position: absolute;
  top: 0;
  right: 10px;
  font-size: 32px;
  cursor: pointer;
}

.modal.active {
  opacity: 1;
  pointer-events: all;
}

.timeline {
  width: 100%;
  max-width: 1180px;
  margin: 0 auto;
  position: relative;
  text-align: center;
}

.timeline__line {
  position: absolute;
  top: 2%;
  width: 8px;
  height: 98%;
  background-color: #0c1830;
}

@media screen and (max-width: 810px) {
  .timeline__line {
    left: 46.20%;
  }
}

@media screen and (max-width: 1179px) {
  .timeline__line {
    left: 45.8%;
  }
}

@media screen and (max-width: 640px) {
  .timeline__line {
    left: 24px;
    top: 2%;
    height: 98%;
  }
}

@media screen and (min-width: 1180px) {
  .timeline__line {
    left: 36.4%;
  }
}

@media screen and (max-width: 640px) {
  .timeline .column {
    display: inline-block;
  }
}

@media screen and (max-width: 640px) {
  .timeline .column {
    display: block;
  }
}

.timeline .row {
  text-align: left;
}

@media screen and (max-width: 640px) {
  .timeline .row {
    width: 100%;
  }
}

.timeline .row div {
  float: left;
}

.timeline .row.ginsburg, .timeline .row.lifestyle {
  padding-bottom: 64px;
}

.timeline .row.doubleSpace {
  padding-bottom: 102px;
}

.timeline .row.partnership {
  padding-bottom: 120px;
}

.timeline .row.join {
  padding-bottom: 50px;
}

.timeline .row__logo {
  min-height: 72px;
  margin-right: 52px;
}

.timeline .row__logo img {
  max-width: 100%;
}

@media screen and (max-width: 640px) {
  .timeline .row__logo {
    display: none;
  }
}

@media screen and (max-width: 1179px) {
  .timeline .row__logo {
    width: 40%;
    margin-right: 5%;
  }
}

@media screen and (min-width: 1180px) {
  .timeline .row__logo {
    width: 368px;
  }
}

.timeline .row__logo.kabel {
  padding-top: 10px;
}

.timeline .row__bullet {
  position: relative;
  padding: 19px 32px 10px;
}

@media screen and (max-width: 640px) {
  .timeline .row__bullet {
    margin-left: 16px;
  }
}

.timeline .row__bullet:before {
  content: '';
  width: 26px;
  height: 26px;
  position: absolute;
  top: 22px;
  left: 0;
  border-radius: 100%;
  background-color: #0c1830;
}

.timeline .row__arrow {
  position: relative;
  font-size: 50px;
}

@media screen and (max-width: 640px) {
  .timeline .row__arrow {
    padding: 0 18px 10px;
  }
}

@media screen and (min-width: 640px) {
  .timeline .row__arrow {
    padding: 0 2px 10px;
  }
}

.timeline .row__description {
  width: 362px;
  padding-top: 20px;
}

@media screen and (min-width: 640px) {
  .timeline .row__description {
    margin-left: 16px;
  }
}

@media screen and (max-width: 1179px) {
  .timeline .row__description {
    width: 30%;
  }
}

@media screen and (max-width: 640px) {
  .timeline .row__description {
    width: 100%;
    padding-left: 50px;
    box-sizing: border-box;
  }
}

@media screen and (min-width: 640px) {
  .timeline .row__description--long {
    width: 460px;
  }
}

@media screen and (max-width: 1179px) {
  .timeline .row__description--long {
    width: 30%;
  }
}

@media screen and (max-width: 640px) {
  .timeline .row__description--long {
    width: 100%;
    padding-left: 50px;
    padding-right: 20px;
    box-sizing: border-box;
  }
}

.timeline .row__popup {
  position: fixed;
  top: 50%;
  left: 50%;
}

.timeline .row a {
  color: #16d772;
  text-decoration: underline;
}

.timeline .addOn .teamBlock div {
  float: inherit;
}

@media screen and (min-width: 640px) {
  .timeline .addOn.modal {
    width: 70%;
    max-width: 852px;
    padding: 0;
    margin: 0 auto;
    float: initial;
  }
}

@media screen and (max-width: 640px) {
  .timeline .addOn.modal {
    width: 90%;
    float: none;
    margin: 0 auto;
  }
}

@media screen and (min-width: 640px) {
  .timeline .addOn.modal .teamBlock {
    width: 90%;
    max-width: 852px;
    padding: 0;
    margin: 32px auto;
    float: initial;
  }
}

@media screen and (max-width: 640px) {
  .timeline .addOn.modal .teamBlock {
    width: 80%;
    max-width: 80%;
    float: none;
    margin: 50px auto 20px;
  }
}

@media screen and (max-width: 640px) {
  .timeline .addOn.modal .teamBlock .teamBlock__description {
    font-size: 16px;
    line-height: 20px;
  }
}

@media screen and (max-width: 640px) {
  .timeline .addOn.modal .teamBlock .teamBlock__name {
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 11px;
  }
}

.servicesBanner.services {
  min-height: 180px;
  position: relative;
}

.servicesBanner__banner {
  width: calc(100% - 40px);
  margin-left: auto;
  margin-right: auto;
  margin-top: 180px;
  max-width: 1000px;
}

.servicesBanner__banner .text {
  font-size: 15px;
  text-align: center;
  color: #fff;
}

.servicesBanner__banner .text strong {
  color: #000;
}

@media screen and (max-width: 640px) {
  .servicesBanner__banner {
    margin-top: 40px;
  }
}

.servicesStep02, .servicesStep03 {
  display: none;
}

@media screen and (min-width: 640px) {
  .servicesStep02 .servicesSlide__overlay {
    width: 60%;
  }
}

@media screen and (max-width: 1200px) {
  .servicesStep02 .servicesSlide__overlay {
    width: 98%;
    box-sizing: border-box;
  }
}

@media screen and (max-width: 816px) {
  .servicesStep02 .servicesSlide__overlay {
    font-size: 38px;
  }
}

@media screen and (max-width: 640px) {
  .servicesStep02 .servicesSlide__overlay {
    font-size: 18px;
    top: 50%;
  }
}

.servicesStep {
  color: white;
  transition: all 0.3s ease-in-out;
}

@media screen and (max-width: 640px) {
  .servicesStep {
    width: 100%;
    margin-bottom: 0;
  }
}

@media screen and (min-width: 640px) {
  .servicesStep {
    width: 50px;
    margin-left: 20px;
    position: absolute;
    z-index: 1;
  }
  .servicesStep.initialPos {
    transform: translateY(0px);
  }
  .servicesStep.secondPos {
    transform: translateY(-60px);
  }
  .servicesStep.thirdPos {
    transform: translateY(-120px);
  }
}

.servicesStep__step {
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
  line-height: 50px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

@media screen and (max-width: 640px) {
  .servicesStep__step {
    display: inline-block;
  }
}

.servicesStep__step.current {
  border-radius: 100%;
  background-color: white;
  color: #1fb0f0;
}

.servicesMobileHint {
  color: #fff;
}

@media screen and (min-width: 640px) {
  .servicesMobileHint {
    display: none;
  }
}

@media screen and (max-width: 640px) {
  .servicesMobileHint {
    display: block;
    margin-bottom: 40px;
  }
}

.servicesPhrase, .optionsBoxes {
  box-sizing: border-box;
}

@media screen and (max-width: 640px) {
  .servicesPhrase, .optionsBoxes {
    width: 90%;
    margin: 0 auto;
  }
}

@media screen and (min-width: 640px) {
  .servicesPhrase, .optionsBoxes {
    width: 66%;
    margin: 0 auto;
  }
}

@media screen and (min-width: 1180px) {
  .servicesPhrase, .optionsBoxes {
    width: 81%;
    padding-left: 218px;
    margin: 0;
  }
}

.servicesPhrase {
  line-height: 64px;
  color: #fff;
  transform: translateY(-10px);
}

@media screen and (min-width: 640px) {
  .servicesPhrase.cardo--50 {
    font-size: 46px;
  }
}

@media screen and (max-width: 1024px) {
  .servicesPhrase {
    padding: 0 70px;
  }
}

@media screen and (max-width: 640px) {
  .servicesPhrase {
    padding: 0;
  }
}

.servicesPhrase .selected {
  position: relative;
  color: #000;
  cursor: pointer;
  transform: translateY(0);
  transition: transform 0.3s ease-in-out;
}

@media screen and (max-width: 640px) {
  .servicesPhrase .selected {
    display: inline-block;
    text-decoration: none;
    box-sizing: border-box;
  }
  .servicesPhrase .selected:after {
    content: '';
    display: block;
    width: 100%;
    margin-top: -3px;
    padding-top: 7px;
    background-color: #fff;
  }
}

@media screen and (min-width: 640px) {
  .servicesPhrase .selected {
    display: inline-block;
    text-decoration: none;
    box-sizing: border-box;
  }
  .servicesPhrase .selected:after {
    content: '';
    display: block;
    width: 100%;
    margin-top: -7px;
    padding-top: 13px;
    background-color: #fff;
  }
  .servicesPhrase .selected:hover {
    transform: translateY(-20px);
  }
}

.servicesPhrase .selected__tooltip {
  position: absolute;
  top: 100px;
  width: 150%;
  text-align: center;
  margin-left: -75%;
  color: white;
}

@media screen and (max-width: 1264px) {
  .servicesPhrase .selected__tooltip {
    display: none;
  }
}

.servicesPhrase .selected i {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -22px;
  font-size: 32px;
}

.servicesSlide {
  position: relative;
  transition: all 0.3s ease-in-out;
}

.servicesSlide.out {
  transform: translate3d(0, -30%, 0);
}

.servicesSlide.in {
  transform: translate3d(0, 0, 0);
}

.servicesSlide__overlay {
  background-color: white;
  position: absolute;
  top: 36%;
  left: 50%;
  transform: translate3d(-50%, -70%, 0);
  opacity: 0;
  pointer-events: none;
  z-index: 500;
  transition: all 0.8s cubic-bezier(0.68, -0.55, 0.27, 1.55);
}

.servicesSlide__overlay.active {
  transform: translate3d(-50%, -50%, 0);
  opacity: 1;
  pointer-events: all;
}

@media screen and (max-width: 640px) {
  .servicesSlide__overlay {
    padding: 30px 30px 20px;
    max-width: 90%;
    min-width: 200px;
  }
}

@media screen and (min-width: 640px) {
  .servicesSlide__overlay {
    padding: 40px 40px 30px;
  }
}

.servicesSlide__overlay li {
  margin-bottom: 6px;
  padding-right: 60px;
  position: relative;
  cursor: pointer;
  transition: color 0.3s ease-in-out;
}

@media screen and (max-width: 640px) {
  .servicesSlide__overlay li {
    line-height: 24px;
    margin-bottom: 16px;
  }
}

.servicesSlide__overlay li span {
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
}

.servicesSlide__overlay li:hover {
  color: #1fb0f0;
}

.servicesSlide__overlay li.selected {
  color: #1fb0f0;
}

.servicesSlide__overlay li.selected span {
  opacity: 1;
}

.servicesSlide .nextButton {
  width: 12%;
  min-width: 110px;
  margin-right: 20px;
  text-align: center;
  background-color: white;
  border-radius: 4px;
  transform: translateY(-8px);
  cursor: pointer;
  box-sizing: border-box;
}

@media screen and (max-width: 640px) {
  .servicesSlide .nextButton {
    height: 44px;
    margin: 30px auto 0;
    font-size: 16px;
    line-height: 44px;
  }
}

@media screen and (min-width: 640px) {
  .servicesSlide .nextButton {
    height: 54px;
    position: absolute;
    right: 0;
    top: 0;
    line-height: 54px;
  }
}

.servicesSlide .nextButton .zmdi {
  margin-left: 4%;
}

@media screen and (max-width: 640px) {
  .servicesSlide .nextButton .zmdi {
    font-size: 16px;
    transform: translateY(2px);
  }
}

@media screen and (min-width: 640px) {
  .servicesSlide .nextButton .zmdi {
    font-size: 28px;
    transform: translateY(5px);
  }
}

.servicesSlide .optionsHint {
  margin-top: 20px;
}

@media screen and (max-width: 640px) {
  .servicesSlide .optionsHint {
    font-size: 12px;
  }
}

.servicesSlide .optionsHintIcon {
  font-size: 32px;
}

@media screen and (max-width: 640px) {
  .servicesSlide .optionsHintIcon {
    display: none;
  }
}

@media screen and (min-width: 640px) {
  .servicesSlide .optionsHintIcon {
    margin-top: -16px;
  }
}

.servicesSlide .optionsBoxes__container {
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
}

@media screen and (max-width: 640px) {
  .servicesSlide .optionsBoxes__container {
    width: 100%;
  }
}

@media screen and (min-width: 640px) {
  .servicesSlide .optionsBoxes__container {
    width: 80%;
  }
}

.servicesSlide .optionsBoxes__box {
  display: flex;
  justify-content: center;
  width: 44%;
  margin: 3%;
  font-size: 18px;
  text-align: center;
  border: 1px solid white;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  float: left;
  transition: all 0.3s ease-in-out;
  box-sizing: border-box;
}

@media screen and (min-width: 640px) {
  .servicesSlide .optionsBoxes__box {
    padding: 40px 10px;
  }
}

@media screen and (max-width: 868px) {
  .servicesSlide .optionsBoxes__box {
    font-size: 15px;
  }
}

@media screen and (max-width: 640px) {
  .servicesSlide .optionsBoxes__box {
    width: 100%;
    padding: 20px 10px;
    margin: 4% auto;
  }
}

.servicesSlide .optionsBoxes__box:hover, .servicesSlide .optionsBoxes__box.selected {
  color: #1fb0f0;
  background-color: white;
}

body.firefox .servicesSlide .optionsBoxes__box {
  margin-bottom: 20px;
}

.services .selectionsModal {
  top: -67px;
}

@media screen and (min-width: 640px) {
  .services .selectionsModal.complete {
    top: -83px;
  }
}

.selectionsModal {
  max-width: 666px;
  min-height: 82px;
  position: absolute;
  top: -130px;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
  background-color: white;
  box-shadow: 0px 11px 25px 2px rgba(0, 0, 0, 0.1);
  display: none;
  opacity: 1;
  pointer-events: all;
  transition: all 0.3s ease-in-out;
}

@media screen and (max-width: 640px) {
  .selectionsModal {
    width: 94%;
  }
}

@media screen and (min-width: 640px) {
  .selectionsModal {
    width: 90%;
  }
}

@media screen and (max-width: 640px) {
  .selectionsModal.cardo--24 {
    font-size: 16px;
    line-height: 16px;
  }
}

.selectionsModal.inactive {
  opacity: 0;
  pointer-events: none;
  transform: translate3d(-50%, -30%, 0);
}

@media screen and (min-width: 640px) {
  .selectionsModal.complete {
    top: -210px;
  }
}

@media screen and (max-width: 640px) {
  .selectionsModal.complete {
    top: -126px;
  }
}

.selectionsModal:after {
  content: '';
  position: absolute;
  left: 50%;
  bottom: -14px;
  width: 0;
  height: 0;
  border-left: 14px solid transparent;
  border-right: 14px solid transparent;
  border-top: 14px solid white;
  transform: translate3d(-50%, 0, 0);
  clear: both;
}

.selectionsModal__step {
  display: none;
  cursor: pointer;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

@media screen and (max-width: 640px) {
  .selectionsModal__step {
    padding: 14px;
  }
}

@media screen and (min-width: 640px) {
  .selectionsModal__step {
    padding: 20px;
  }
}

.selectionsModal__step.active {
  opacity: 1;
}

.selectionsModal__step:nth-child(2) {
  border-top: 1px solid #F2F2F2;
}

.selectionsModal .zmdi {
  font-size: 32px;
  transform: translateY(6px);
  margin-left: 10px;
}

@media screen and (max-width: 640px) {
  .selectionsModal .zmdi {
    line-height: 14px;
  }
}

.servicesBanner[data-selection*="early"] .optionsBoxes:not(.earlyStages), .servicesBanner[data-selection*="none"] .optionsBoxes:not(.earlyStages) {
  display: none;
}

.servicesBanner[data-selection*="10"] .optionsBoxes:not(.tenYears) {
  display: none;
}

.servicesBanner[data-selection*="20"] .optionsBoxes:not(.twentyYears) {
  display: none;
}

.servicesBanner[data-selection*="planning"] .optionsBoxes:not(.planningToRetire) {
  display: none;
}

.servicesBanner[data-selection*="retired"] .optionsBoxes:not(.retired) {
  display: none;
}

/**
 * BxSlider v4.1.2 - Fully loaded, responsive content slider
 * http://bxslider.com
 *
 * Written by: Steven Wanderski, 2014
 * http://stevenwanderski.com
 * (while drinking Belgian ales and listening to jazz)
 *
 * CEO and founder of bxCreative, LTD
 * http://bxcreative.com
 */
/** RESET AND LAYOUT
===================================*/
.bx-wrapper {
  position: relative;
  margin: 0 auto 60px;
  padding: 0;
  *zoom: 1;
}

.bx-wrapper img {
  max-width: 100%;
  display: block;
}

/** THEME
===================================*/
.bx-wrapper .bx-viewport {
  background: #fff;
  -webkit-transform: translatez(0);
  -moz-transform: translatez(0);
  -ms-transform: translatez(0);
  -o-transform: translatez(0);
  transform: translatez(0);
}

.bx-wrapper .bx-pager,
.bx-wrapper .bx-controls-auto {
  position: absolute;
  bottom: -30px;
  width: 100%;
}

/* LOADER */
.bx-wrapper .bx-loading {
  min-height: 50px;
  background: url(images/bx_loader.gif) center center no-repeat #fff;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2000;
}

/* PAGER */
.bx-wrapper .bx-pager {
  text-align: center;
  font-size: .85em;
  font-family: Arial;
  font-weight: bold;
  color: #666;
  padding-top: 20px;
}

.bx-wrapper .bx-pager .bx-pager-item,
.bx-wrapper .bx-controls-auto .bx-controls-auto-item {
  display: inline-block;
  *zoom: 1;
  *display: inline;
}

.bx-wrapper .bx-pager.bx-default-pager a {
  background: #666;
  text-indent: -9999px;
  display: block;
  width: 10px;
  height: 10px;
  margin: 0 5px;
  outline: 0;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
}

.bx-wrapper .bx-pager.bx-default-pager a:hover,
.bx-wrapper .bx-pager.bx-default-pager a.active {
  background: #000;
}

/* DIRECTION CONTROLS (NEXT / PREV) */
.bx-wrapper .bx-prev {
  left: 10px;
  background: url(images/controls.png) no-repeat 0 -32px;
}

.bx-wrapper .bx-next {
  right: 10px;
  background: url(images/controls.png) no-repeat -43px -32px;
}

.bx-wrapper .bx-prev:hover {
  background-position: 0 0;
}

.bx-wrapper .bx-next:hover {
  background-position: -43px 0;
}

.bx-wrapper .bx-controls-direction a {
  position: absolute;
  top: 50%;
  margin-top: -16px;
  outline: 0;
  width: 32px;
  height: 32px;
  text-indent: -9999px;
  z-index: 9999;
}

.bx-wrapper .bx-controls-direction a.disabled {
  display: none;
}

/* AUTO CONTROLS (START / STOP) */
.bx-wrapper .bx-controls-auto {
  text-align: center;
}

.bx-wrapper .bx-controls-auto .bx-start {
  display: block;
  text-indent: -9999px;
  width: 10px;
  height: 11px;
  outline: 0;
  background: url(images/controls.png) -86px -11px no-repeat;
  margin: 0 3px;
}

.bx-wrapper .bx-controls-auto .bx-start:hover,
.bx-wrapper .bx-controls-auto .bx-start.active {
  background-position: -86px 0;
}

.bx-wrapper .bx-controls-auto .bx-stop {
  display: block;
  text-indent: -9999px;
  width: 9px;
  height: 11px;
  outline: 0;
  background: url(images/controls.png) -86px -44px no-repeat;
  margin: 0 3px;
}

.bx-wrapper .bx-controls-auto .bx-stop:hover,
.bx-wrapper .bx-controls-auto .bx-stop.active {
  background-position: -86px -33px;
}

/* PAGER WITH AUTO-CONTROLS HYBRID LAYOUT */
.bx-wrapper .bx-controls.bx-has-controls-auto.bx-has-pager .bx-pager {
  text-align: left;
  width: 80%;
}

.bx-wrapper .bx-controls.bx-has-controls-auto.bx-has-pager .bx-controls-auto {
  right: 0;
  width: 35px;
}

/* IMAGE CAPTIONS */
.bx-wrapper .bx-caption {
  position: absolute;
  bottom: 0;
  left: 0;
  background: #666\9;
  background: rgba(80, 80, 80, 0.75);
  width: 100%;
}

.bx-wrapper .bx-caption span {
  color: #fff;
  font-family: Arial;
  display: block;
  font-size: .85em;
  padding: 10px;
}

.weather__top, .weather__bottom {
  position: absolute;
  bottom: 0;
  color: white;
}

.weather__top {
  width: 186px;
  height: 180px;
  left: 0;
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  background-color: #1fb0f0;
}

.weather__top div {
  width: 100%;
  text-align: center;
}

.weather__bottom {
  width: 130px;
  height: 164px;
  padding-top: 10px;
  padding-left: 10px;
  right: 0;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  background-color: #0c1830;
  box-sizing: border-box;
}

.weather__bottom:before {
  content: '';
  position: absolute;
  top: -16px;
  left: 0;
  width: 0;
  height: 0;
  border-top: 16px solid transparent;
  border-bottom: 0 solid transparent;
  border-right: 16px solid #dee6e9;
}

.weather__bottom .weather__summary {
  line-height: 22px;
  margin-top: 12px;
}

.weather__summary {
  line-height: 48px;
  padding-right: 7px;
}

.weather__temperature {
  margin-bottom: 10px;
  font-size: 70px;
  line-height: 57px;
  box-sizing: border-box;
}

.weather__apparentTemperature {
  font-size: 24px;
  line-height: 32px;
  opacity: .5;
  box-sizing: border-box;
}

.weather__city {
  color: #1fb0f0;
}

.weather__icon {
  width: 30px;
  margin-top: 16px;
}

.weather__icon path {
  fill: #FFF;
}

.spinner {
  width: 40px;
  height: 40px;
  margin: 100px auto;
  background-color: #FFF;
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 500;
  margin-top: -20px;
  margin-left: -20px;
  border-radius: 100%;
  -webkit-animation: sk-scaleout 1.0s infinite ease-in-out;
  animation: sk-scaleout 1.0s infinite ease-in-out;
}

@-webkit-keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
    opacity: 0;
  }
}

@keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}

.sackers--14 {
  font-family: "SackersGothicStd-Heavy", sans-serif;
  font-size: 14px;
  line-height: 24px;
  font-weight: normal;
  font-style: normal;
}

.sackers--19, .nav__item {
  font-family: "SackersGothicStd-Heavy", sans-serif;
  font-size: 19px;
  line-height: 24px;
  font-weight: normal;
  font-style: normal;
}

.cardo--53 {
  font-family: "Cardo", serif;
}

@media screen and (min-width: 640px) {
  .cardo--53 {
    font-size: 53px;
    line-height: 60px;
  }
}

@media screen and (max-width: 640px) {
  .cardo--53 {
    font-size: 30px;
    line-height: 40px;
  }
}

.cardo--50 {
  font-family: "Cardo", serif;
}

@media screen and (min-width: 640px) {
  .cardo--50 {
    font-size: 50px;
    line-height: 59px;
  }
}

@media screen and (max-width: 640px) {
  .cardo--50 {
    font-size: 30px;
    line-height: 36px;
  }
}

.cardo--40 {
  font-family: "Cardo", serif;
}

@media screen and (min-width: 640px) {
  .cardo--40 {
    font-size: 40px;
    line-height: 50px;
  }
}

@media screen and (max-width: 640px) {
  .cardo--40 {
    font-size: 30px;
    line-height: 35px;
  }
}

.cardo--32 {
  font-family: "Cardo", serif;
}

@media screen and (min-width: 640px) {
  .cardo--32 {
    font-size: 32px;
    line-height: 43px;
  }
}

@media screen and (max-width: 1024px) {
  .cardo--32 {
    font-size: 28px;
  }
}

@media screen and (max-width: 640px) {
  .cardo--32 {
    font-size: 24px;
    line-height: 35px;
  }
}

.cardo--30, .containerWidth.landingPage p, .containerWidth.landingPage h1 {
  font-family: "Cardo", serif;
}

@media screen and (min-width: 640px) {
  .cardo--30, .containerWidth.landingPage p, .containerWidth.landingPage h1 {
    font-size: 30px;
    line-height: 43px;
  }
}

@media screen and (max-width: 640px) {
  .cardo--30, .containerWidth.landingPage p, .containerWidth.landingPage h1 {
    font-size: 24px;
    line-height: 35px;
  }
}

.cardo--24 {
  font-family: "Cardo", serif;
}

@media screen and (min-width: 640px) {
  .cardo--24 {
    font-size: 24px;
    line-height: 43px;
  }
}

@media screen and (max-width: 1024px) {
  .cardo--24 {
    font-size: 18px;
  }
}

@media screen and (max-width: 640px) {
  .cardo--24 {
    font-size: 18px;
    line-height: 32px;
  }
}

.openSans--R30 {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 30px;
  line-height: 60px;
}

.openSans--R24 {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
}

@media screen and (min-width: 640px) {
  .openSans--R24 {
    font-size: 24px;
    line-height: 28px;
  }
}

@media screen and (max-width: 640px) {
  .openSans--R24 {
    font-size: 18px;
    line-height: 32px;
  }
}

.openSans--R20 {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
}

@media screen and (min-width: 640px) {
  .openSans--R20 {
    font-size: 20px;
    line-height: 36px;
  }
}

@media screen and (max-width: 640px) {
  .openSans--R20 {
    font-size: 16px;
    line-height: 25px;
  }
}

.openSans--SB20, .containerWidth.landingPage h2 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 20px;
  line-height: 31px;
}

.openSans--R20 {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
}

.openSans--R19 {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 19px;
  line-height: 32px;
}

.openSans--R18 {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
}

.openSans--R16, #nav--mobile .nav__item {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
}

.openSans--SB16 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 23px;
}

.openSans--SB19 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 19px;
  line-height: 32px;
}

.openSans--R14 {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}

.openSans--B14 {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
}

.openSans--R12 {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
}

.underlinedLink {
  font-family: "Cardo", serif;
  font-size: 32px;
  line-height: 43px;
  display: inline-block;
  text-decoration: none;
  box-sizing: border-box;
}

.underlinedLink:after {
  content: '';
  display: block;
  width: 100%;
  margin-top: -7px;
  padding-top: 13px;
  background-color: #dee6e9;
}

@media screen and (min-width: 640px) {
  .underlinedLink {
    font-size: 32px;
    line-height: 43px;
  }
}

@media screen and (max-width: 640px) {
  .underlinedLink {
    font-size: 20px;
    line-height: 43px;
  }
}

.footer__col {
  float: left;
  box-sizing: border-box;
}

@media screen and (min-width: 960px) {
  .footer__col {
    width: 33.3333333333%;
  }
}

@media screen and (max-width: 960px) {
  .footer__col {
    width: 100%;
  }
}

@media screen and (max-width: 960px) {
  .footer__col .openSans--R20, .openSans--R20.footer__by, .footer__col .openSans--SB20, .footer__col .containerWidth.landingPage h2, .containerWidth.landingPage .footer__col h2,
  .openSans--SB20.footer__by,
  .containerWidth.landingPage h2.footer__by {
    font-size: 14px;
    line-height: 25px;
  }
}

@media screen and (min-width: 960px) {
  .footer__col--left {
    text-align: left;
  }
}

@media screen and (max-width: 960px) {
  .footer__col--left {
    text-align: center;
  }
}

@media screen and (min-width: 960px) {
  .footer__col--right {
    text-align: right;
  }
}

@media screen and (max-width: 960px) {
  .footer__col--right {
    text-align: center;
  }
}

.footer {
  text-align: center;
}

.footer__top, .footer__bottom {
  background-color: #dee6e9;
}

.footer .fpa-logo {
  max-width: 245px;
}

@media screen and (max-width: 960px) {
  .footer .mobileHidden {
    display: none;
  }
}

@media screen and (min-width: 960px) {
  .footer .mobileOnly {
    display: none;
  }
}

@media screen and (max-width: 960px) {
  .footer .mobileOnly {
    display: block;
  }
}

.footer__top {
  border-bottom: 3px solid #fff;
}

@media screen and (min-width: 960px) {
  .footer__top {
    padding: 0 23px;
  }
}

@media screen and (max-width: 960px) {
  .footer__top {
    padding: 0 18px;
  }
}

@media screen and (min-width: 960px) {
  .footer__top .footer__col--left,
  .footer__top .footer__col--right {
    padding-top: 30px;
  }
}

@media screen and (max-width: 960px) {
  .footer__top .footer__col--right {
    padding: 16px 0 35px;
  }
}

@media screen and (min-width: 960px) {
  .footer__top .footer__col--center {
    padding: 52px 0;
  }
}

@media screen and (max-width: 960px) {
  .footer__top .footer__col--center {
    padding: 30px 0;
  }
}

.footerResources {
  color: #667682;
  display: inline-block;
  transition: color 0.3s ease-in-out;
}

.footerResources:hover {
  color: #0c1830;
}

.footerResources .zmdi,
.footerResources .sackers--19,
.footerResources .nav__item {
  display: inline-block;
  float: left;
}

.footerResources .zmdi {
  font-size: 40px;
  margin-right: 18px;
}


.footerResources .sackers--19,
.footerResources .nav__item {
  margin-top: 12px;
}

.footer__logo {
  width: 100%;
}

@media screen and (min-width: 960px) {
  .footer__logo {
    max-width: 458px;
  }
}

@media screen and (max-width: 960px) {
  .footer__logo {
    max-width: 293px;
  }
}

.fpa-logo {
  display: inline-block;
  width: 100%;
}

.fpa-logo:hover #fpaLogo path {
  fill: #667682;
}

#fpaLogo path {
  fill: #000;
  transition: all 0.3s ease-in-out;
}

@media screen and (min-width: 960px) {
  .footer__bottom {
    padding: 65px 23px 40px;
  }
}

@media screen and (max-width: 960px) {
  .footer__bottom {
    padding: 43px 18px 46px;
  }
}

.footer__bottom .zmdi {
  font-size: 19px;
}

.footer__bottom .footer__col--center {
  color: #0c1830;
}

@media screen and (min-width: 960px) {
  .footer__bottom .footer__col--center {
    padding: 0 23px;
    box-sizing: border-box;
  }
}

@media screen and (max-width: 960px) {
  .footer__bottom .footer__col--center {
    margin-bottom: 18px;
  }
}

.footer__bottom .footer__col--center a {
  color: #0c1830;
  transition: color 0.3s ease-in-out;
}

.footer__bottom .footer__col--center a:hover {
  color: #1fb0f0;
}

.footer__bottom .mobileOnly {
  margin-bottom: 20px;
}

.footer__social {
  margin-top: 10px;
}

@media screen and (min-width: 960px) {
  .footer__social .zmdi {
    font-size: 36px;
    padding: 0 7px;
  }
}

@media screen and (max-width: 960px) {
  .footer__social .zmdi {
    font-size: 30px;
    padding: 0 7px;
  }
}

.footer__maps {
  text-align: center;
  background-color: #0c1830;
  color: #fff;
}

@media screen and (min-width: 960px) {
  .footer__maps {
    padding: 19px 23px;
  }
}

@media screen and (max-width: 960px) {
  .footer__maps {
    padding: 19px 18px;
  }
}

.footer__maps .zmdi, .footer__maps a {
  color: #1fb0f0;
}

.footer__maps a {
  transition: color 0.3s ease-in-out;
}

.footer__maps a:hover {
  color: #fff;
}

.footer__by {
  color: #667682;
  padding: 19px 23px;
}

.footer__by a {
  color: #667682;
  transition: all 0.3s ease-in-out;
}

.footer__by a:hover {
  color: #0c1830;
}

.securityAndPrivacy {
  transition: color 0.3s ease-in-out;
  cursor: pointer;
}

.securityAndPrivacy:hover {
  color: #0c1830;
}

.securityAndPrivacy__container {
  padding: 40px;
  margin-top: 40px;
  margin-bottom: 20px;
}

.securityAndPrivacy__container a {
  color: #16d772;
  text-decoration: underline;
}

.navContainer {
  position: fixed;
  width: 100%;
  z-index: 200;
  backface-visibility: hidden;
}

.navBar {
  background-color: #fff;
  overflow: hidden;
  position: relative;
}

@media screen and (min-width: 996px) {
  .navBar {
    padding-top: 37px;
  }
}

@media screen and (max-width: 996px) {
  .navBar {
    padding-top: 0;
  }
}

.navBar__logo {
  display: inline-block;
  float: left;
  height: auto;
  border-bottom-style: solid;
  border-bottom-color: #dee6e9;
}

@media screen and (min-width: 996px) {
  .navBar__logo {
    width: 195px;
    padding: 27px 22px 25px;
    border-bottom-width: 3px;
  }
}

@media screen and (max-width: 996px) {
  .navBar__logo {
    width: 100%;
    padding: 23px 21px 21px;
    border-bottom-width: 2px;
  }
}

.navBar__logo img {
  height: auto;
}

@media screen and (min-width: 996px) {
  .navBar__logo img {
    width: 100%;
  }
}

@media screen and (max-width: 996px) {
  .navBar__logo img {
    width: 170px;
  }
}

.navBar .nav__item.active a {
  border-bottom-color: #222461;
}

.navBar .nav__item a {
  padding: 25px 15px;
  border-bottom-style: solid;
  border-bottom-color: #dee6e9;
  border-bottom-width: 3px;
  transition: border-bottom-color 0.3s ease-in-out;
}

.navBar .nav__item a:hover {
  border-bottom-color: #222461;
}

.navBar .nav {
  margin-top: 11px;
  display: inline-block;
  float: left;
}

@media screen and (max-width: 996px) {
  .navBar .nav {
    display: none;
  }
}

.nav__item,
.navRight {
  display: inline-block;
}

.nav__item a,
.navRight a {
  display: inline-block;
  text-decoration: none;
  color: #667682;
}

.nav__item {
  float: left;
}

.navRight {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 10;
}

.navRight a {
  border-bottom-style: solid;
  border-bottom-color: transparent;
  border-bottom-width: 3px;
  transition: border-bottom-color 0.3s ease-in-out;
}

@media screen and (max-width: 640px) {
  .navRight a {
    padding: 4px 16px 0;
  }
}

@media screen and (min-width: 640px) {
  .navRight a {
    padding: 8px 16px;
  }
  .navRight a:hover {
    border-bottom-color: #1fb0f0;
  }
}

@media screen and (max-width: 996px) {
  .navRight .documents {
    display: none;
  }
}

.navRight .zmdi {
  margin-right: 8px;
}

@media screen and (min-width: 996px) {
  #nav--mobile,
  #mobileHamburgerIcon {
    display: none;
  }
}

@media screen and (max-width: 996px) {
  #nav--mobile,
  #mobileHamburgerIcon {
    display: inline-block;
  }
}

#mobileHamburgerIcon {
  font-size: 21px;
  color: #222461;
  cursor: pointer;
  opacity: 1;
  pointer-events: all;
  transform: rotate(0deg);
}

#mobileHamburgerIcon.open {
  opacity: 0;
  pointer-events: none;
}

.navBar #mobileHamburgerIcon {
  position: absolute;
  top: 18px;
  right: 8px;
  padding: 10px;
  z-index: 10;
}

#nav--mobile {
  opacity: 0;
  pointer-events: none;
  width: 100%;
  height: 100%;
  padding: 30px;
  position: fixed;
  z-index: 200;
  background-color: #fff;
  transform: translate3d(0, 0, 0);
  box-sizing: border-box;
  transition: opacity 0.3s ease-in-out;
}

#nav--mobile .menu__list {
  width: 100%;
  display: block;
  margin-top: 54px;
  text-align: center;
}

#nav--mobile .nav__item {
  display: block;
  float: none;
}

#nav--mobile .nav__item__link {
  padding: 18px 0;
  display: block;
}

#nav--mobile.open {
  opacity: 1;
  pointer-events: all;
}

#mobileCloseIcon {
  cursor: pointer;
  padding: 13px 20px;
  position: absolute;
  right: -2px;
  top: 12px;
  font-size: 26px;
  color: #222461;
}

.documents {
  float: right;
}

.hero {
  box-sizing: border-box;
  padding-bottom: 0;
}

@media screen and (min-width: 996px) {
  .hero {
    padding-left: 23px;
    padding-right: 23px;
    margin-top: 65px;
  }
}

@media screen and (max-width: 996px) {
  .hero {
    padding-top: 28px;
  }
}

@media screen and (max-width: 1024px) {
  .hero {
    padding-left: 18px;
    padding-right: 18px;
  }
}

.hero .top {
  position: relative;
}

.hero .top__title {
  width: 100%;
  box-sizing: border-box;
}

@media screen and (min-width: 1024px) {
  .hero .top__title {
    padding: 0 323px 0 42px;
  }
}

@media screen and (min-width: 996px) {
  .hero .top__title {
    margin-bottom: 68px;
  }
}

@media screen and (max-width: 996px) {
  .hero .top__title {
    margin-bottom: 33px;
  }
}

@media screen and (max-width: 1024px) {
  .hero .top__title {
    padding: 0 42px;
  }
}

@media screen and (max-width: 640px) {
  .hero .top__title {
    padding: 0;
  }
}

.hero .top__weather {
  width: 300px;
  height: 180px;
  position: absolute;
  top: -30px;
  right: 0;
}

@media screen and (max-width: 1024px) {
  .hero .top__weather {
    display: none;
  }
}

.heroNext {
  width: 100%;
  box-sizing: border-box;
}

@media screen and (min-width: 1024px) {
  .heroNext {
    padding: 0 218px 0;
    margin-top: 83px;
  }
}

@media screen and (max-width: 1024px) {
  .heroNext {
    text-align: center;
    margin-top: 52px;
  }
}

.heroNext .cardo--30, .heroNext .containerWidth.landingPage p, .containerWidth.landingPage .heroNext p, .heroNext .containerWidth.landingPage h1, .containerWidth.landingPage .heroNext h1 {
  margin-bottom: 25px;
}

.heroNext__cta {
  color: #1fb0f0;
  display: inline-block;
  text-align: center;
  cursor: pointer;
}

@media screen and (min-width: 1024px) {
  .heroNext__cta {
    margin-bottom: 78px;
  }
}

@media screen and (max-width: 1024px) {
  .heroNext__cta {
    margin-bottom: 40px;
  }
}

.heroNext__cta .zmdi {
  font-size: 40px;
}

.servicesBanner {
  background-color: #1fb0f0;
  width: 100%;
}

@media screen and (max-width: 640px) {
  .servicesBanner {
    padding: 40px 0 40px;
  }
}

@media screen and (min-width: 640px) {
  .servicesBanner {
    padding: 170px 0 130px;
  }
}

@media screen and (min-width: 1024px) {
  .twoBoxes {
    padding: 86px 0 80px;
  }
}

@media screen and (max-width: 1024px) {
  .twoBoxes {
    padding: 23px 0 29px;
  }
}

.twoBoxesBox__container {
  border: 2px solid #dee6e9;
}

.twoBoxesBox,
.twoBoxesBox__header,
.twoBoxesBox__text {
  box-sizing: border-box;
}

.twoBoxesBox {
  float: left;
}

@media screen and (min-width: 1024px) {
  .twoBoxesBox {
    width: 50%;
    padding: 0 38px 0;
  }
}

@media screen and (max-width: 1024px) {
  .twoBoxesBox {
    width: 100%;
    padding: 0 18px 0;
  }
}

.twoBoxesBox--mortgage .twoBoxesBox__header {
  color: #fff;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: url(../imgs/mortgage-bg-2x.jpg);
}

.twoBoxesBox--mortgage a {
  color: #f37522;
}

@media screen and (min-width: 1024px) {
  .twoBoxesBox--mortgage .underlinedLink {
    margin: 22px 0 0 40px;
  }
}

@media screen and (max-width: 1024px) {
  .twoBoxesBox--mortgage .underlinedLink {
    margin: 22px 0 34px 0;
  }
}

@media screen and (max-width: 640px) {
  .twoBoxesBox--services .twoBoxesBox__header {
    padding: 26px;
  }
}

.twoBoxesBox--services .openSans--B14 {
  color: #222461;
}

.twoBoxesBox__header {
  position: relative;
}

@media screen and (min-width: 1024px) {
  .twoBoxesBox__header {
    padding: 21px 40px 42px;
  }
}

@media screen and (max-width: 1024px) {
  .twoBoxesBox__header {
    padding: 26px 26px 85px;
  }
}

.twoBoxesBox__header a {
  z-index: 2;
  display: block;
  position: inherit;
}

.twoBoxesBox__text {
  border-top: 2px solid #dee6e9;
}

@media screen and (min-width: 1024px) {
  .twoBoxesBox__text {
    padding: 30px 40px 35px;
  }
}

@media screen and (max-width: 1024px) {
  .twoBoxesBox__text {
    padding: 21px 20px 15px;
  }
}

.twoBoxesBox__text .openSans--R14 {
  margin-bottom: 24px;
}

.twoBoxesBox__icon {
  margin-bottom: 4px;
}

.teamPhoto {
  width: 100%;
  height: auto;
}

@media screen and (min-width: 640px) {
  .teamPhoto {
    margin-top: 70px;
  }
}

@media screen and (max-width: 640px) {
  .teamPhoto {
    margin-top: 40px;
  }
}

.teamPhoto img {
  width: 100%;
}

.teamPhoto__text {
  max-width: 850px;
}

@media screen and (min-width: 640px) {
  .teamPhoto__text {
    width: 75%;
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (max-width: 640px) {
  .teamPhoto__text {
    width: 100%;
    box-sizing: border-box;
    padding: 0 18px;
  }
}

.teamPhoto__text a {
  color: #1fb0f0;
}

.teamPhoto__text span {
  color: #222461;
}

.logosSection {
  overflow: hidden;
}

@media screen and (min-width: 1024px) {
  .logosSection {
    padding: 60px 0 143px 0;
  }
}

@media screen and (max-width: 1024px) {
  .logosSection {
    padding: 20px 0;
  }
}

.logosSection .tickerLogos {
  width: 300px;
  overflow: hidden;
}

.logosSection .tickerLogo {
  display: inline-block;
  float: left;
  text-align: center;
}

.logosSection img {
  margin: 0 34px;
  width: 100%;
  max-width: 300px;
}

.logoSection__heading {
  padding: 20px;
  color: #293239;
  text-align: center;
}

@media screen and (min-width: 1024px) {
  .logoSection__heading {
    margin-bottom: 60px;
    min-height: 60px;
  }
}

.containerWidth.testimonials {
  position: relative;
}

@media screen and (max-width: 1024px) {
  .containerWidth.testimonials {
    padding: 0 18px;
    box-sizing: border-box;
  }
}

@media screen and (min-width: 640px) {
  .servicesTiles {
    padding-top: 70px;
  }
}

@media screen and (max-width: 640px) {
  .servicesTiles {
    padding-top: 18px;
  }
}

@media screen and (min-width: 640px) {
  .servicesTilesWithSelector {
    padding-top: 250px;
  }
}

@media screen and (min-width: 640px) {
  .servicesTilesWithSelector.onlyCat {
    padding-top: 180px;
  }
}

.servicesTiles .servicesHeading,
.servicesTiles .servicesSubHeading {
  text-align: center;
  box-sizing: border-box;
}

@media screen and (min-width: 640px) {
  .servicesTiles .servicesHeading,
  .servicesTiles .servicesSubHeading {
    padding: 0 23px;
  }
}

@media screen and (max-width: 640px) {
  .servicesTiles .servicesHeading,
  .servicesTiles .servicesSubHeading {
    padding: 0 18px;
  }
}

.servicesTiles .servicesHeading {
  color: #667682;
  margin-bottom: 6px;
}

.servicesTiles .servicesSubHeading {
  color: #667682;
}

@media screen and (min-width: 640px) {
  .servicesTiles .servicesSubHeading {
    margin-bottom: 60px;
  }
}

@media screen and (max-width: 640px) {
  .servicesTiles .servicesSubHeading {
    font-size: 13px;
    margin-bottom: 29px;
  }
}

.serviceBox__container {
  border-style: solid;
  border-width: 2px;
  border-color: #dee6e9;
  transition: all 0.3s ease-in-out;
}

@media screen and (min-width: 1024px) {
  .serviceBox__container {
    min-height: 380px;
  }
}

.serviceBox,
.serviceBox__headerText,
.serviceBox__text {
  box-sizing: border-box;
}

@media screen and (max-width: 1268px) {
  .serviceBox__headerText .cardo--40 {
    font-size: 32px;
    line-height: 40px;
  }
}

.servicesBoxes {
  color: #667682;
}

@media screen and (min-width: 640px) {
  .servicesBoxes {
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 640px) {
  .servicesBoxes {
    margin-bottom: 0;
  }
}

@media screen and (min-width: 1024px) {
  .servicesBoxes {
    padding: 0 8px;
  }
}

.serviceBox {
  float: left;
  text-align: left;
  transform: translate3d(0, 0, 0);
}

.serviceBox:hover .serviceBox__text, .serviceBox.open .serviceBox__text {
  border-top-color: transparent;
  box-shadow: 0px 0px 18px 3px rgba(0, 0, 0, 0.13);
}

.serviceBox:hover .serviceBox__container, .serviceBox.open .serviceBox__container {
  border-color: transparent;
}

@media screen and (min-width: 1024px) {
  .serviceBox {
    width: 24%;
    padding: 15px;
  }
}

@media screen and (max-width: 1024px) {
  .serviceBox {
    width: 100%;
    padding: 0 18px 18px;
  }
}

.serviceBox a {
  color: #667682;
  transition: all 0.3s ease-in-out;
}

.serviceBox a:hover {
  color: #1fb0f0;
}

.serviceBox .choosenTagsContainer {
  width: 100%;
  padding-right: 20px;
  box-sizing: border-box;
}

.serviceBox .serviceDescription {
  transition: all 0.3s ease-in-out;
}

@media screen and (min-width: 1024px) {
  .serviceBox .serviceDescription {
    min-height: 48px;
  }
}

.serviceBox .serviceDescription.expanded {
  padding-right: 20px;
}

.serviceBox .serviceDescription.expanded, .serviceBox .serviceDescription.initial {
  opacity: 0;
  pointer-events: none;
  display: none;
}

.serviceBox .serviceDescription.expanded.show, .serviceBox .serviceDescription.initial.show {
  display: block;
}

.serviceBox .serviceDescription.expanded.active, .serviceBox .serviceDescription.initial.active {
  opacity: 1;
  pointer-events: all;
}

.serviceBox .choosenTagsExpanded {
  display: none;
}

.serviceBox__header {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: #fff;
  position: relative;
  overflow: hidden;
}

@media screen and (min-width: 1024px) {
  .serviceBox__header {
    min-height: 244px;
  }
}

@media screen and (max-width: 1024px) {
  .serviceBox__header {
    min-height: 175px;
  }
}

.serviceBox__headerText {
  z-index: 2;
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 28px 25px 60px;
  box-sizing: border-box;
}

.serviceBox__overlay, .twoBoxesBox__overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: .2;
  top: 0;
  left: 0;
  z-index: 1;
}

.serviceBox__text {
  background-color: #fff;
  border-top-style: solid;
  border-top-width: 2px;
  border-top-color: #dee6e9;
  position: relative;
}

@media screen and (min-width: 1024px) {
  .serviceBox__text {
    padding: 20px 25px 25px;
    min-height: 170px;
  }
}

@media screen and (max-width: 1270px) {
  .serviceBox__text {
    padding: 20px 18px 25px;
  }
}

@media screen and (max-width: 1024px) {
  .serviceBox__text {
    padding: 20px 25px 25px;
  }
}

@media screen and (max-width: 1038px) {
  .serviceBox__text .openSans--B14 {
    font-size: 12px;
    line-height: 18px;
  }
}

@media screen and (max-width: 640px) {
  .serviceBox__text .openSans--B14 {
    font-size: 14px;
    line-height: 24px;
  }
}

.serviceBox__text .openSans--R14 {
  margin-bottom: 24px;
}

@media screen and (max-width: 1038px) {
  .serviceBox__text .openSans--R14 {
    font-size: 12px;
    line-height: 18px;
  }
}

@media screen and (max-width: 640px) {
  .serviceBox__text .openSans--R14 {
    font-size: 14px;
    line-height: 24px;
  }
}

.serviceBox__text .zmdi-plus-circle, .serviceBox__text .zmdi-minus-circle {
  position: absolute;
  font-size: 30px;
  color: #1fb0f0;
  bottom: 13px;
  right: 13px;
  cursor: pointer;
}

.serviceBox__icon {
  margin-bottom: 4px;
  max-width: 55px;
}

.servicesCustomiserContainer {
  padding-top: 100px;
}

.servicesCustomiser {
  text-align: center;
  box-sizing: border-box;
}

@media screen and (min-width: 640px) {
  .servicesCustomiser {
    margin-bottom: 125px;
    padding: 0 23px;
  }
}

@media screen and (max-width: 1024px) {
  .servicesCustomiser {
    margin-bottom: 80px;
    padding: 0 23px;
  }
}

@media screen and (max-width: 640px) {
  .servicesCustomiser {
    padding: 0 18px;
    margin-bottom: 45px;
  }
}

.servicesCustomiser .underlinedLink {
  color: #222461;
}

.serviceSearch {
  text-align: center;
  box-sizing: border-box;
}

@media screen and (min-width: 640px) {
  .serviceSearch {
    margin-bottom: 130px;
  }
}

@media screen and (max-width: 640px) {
  .serviceSearch {
    margin-bottom: 42px;
  }
}

@media screen and (min-width: 640px) {
  .serviceSearch {
    padding: 0 23px;
  }
}

@media screen and (max-width: 640px) {
  .serviceSearch {
    padding: 0 18px;
  }
}

.serviceSearch .cardo--32 {
  color: #0c1830;
  display: inline-block;
  margin-right: 25px;
}

@media screen and (max-width: 1024px) {
  .serviceSearch .cardo--32 {
    margin-bottom: 25px;
  }
}

@media screen and (max-width: 640px) {
  .serviceSearch .cardo--32 {
    font-size: 20px;
  }
}

.input--servicesSearch {
  width: 25%;
  font-size: 30px;
  position: relative;
}

@media screen and (max-width: 1024px) {
  .input--servicesSearch {
    width: 100%;
    min-width: 240px;
  }
}

@media screen and (max-width: 1024px) {
  .input--servicesSearch .input--services {
    width: 100%;
  }
}

.input--servicesSearch .zmdi-search {
  position: absolute;
  right: 8px;
  bottom: 0px;
}

.servicesHero {
  opacity: 1;
  max-height: 1000px;
  transition: all 0.3s ease-in-out;
}

.servicesHero.hide {
  opacity: 0;
}

@media screen and (min-width: 1024px) {
  .servicesHero .heroNext {
    padding: 0 162px;
  }
}

@media screen and (min-width: 640px) {
  .servicesHero .heroNext div.openSans--R20 {
    text-align: left;
  }
}

@media screen and (max-width: 640px) {
  .servicesHero .heroNext {
    margin-top: 22px;
  }
  .servicesHero .heroNext div.openSans--R20 {
    text-align: center;
  }
}

.servicesSearchHeading .selectionsModal__step, .servicesSearchHeading .selectionsModal {
  display: block;
}

.servicesSearchHeading .selectionsModal {
  top: 180px;
}

.servicesTiles .servicesSearchHeading .selectionsModal__step, .servicesTiles .servicesSearchHeading .selectionsModal {
  cursor: default;
}

.servicesOthers {
  background-color: #dee6e9;
}

@media screen and (min-width: 640px) {
  .servicesOthers {
    padding: 112px 0 48px;
  }
}

@media screen and (max-width: 640px) {
  .servicesOthers {
    padding: 62px 0 48px;
  }
}

.servicesOthersHeading {
  text-align: center;
  color: #0c1830;
}

@media screen and (min-width: 640px) {
  .servicesOthersHeading {
    margin-bottom: 60px;
  }
}

@media screen and (max-width: 640px) {
  .servicesOthersHeading {
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 640px) {
  .recommendedServices.servicesTiles {
    padding-top: 190px;
  }
}

@media screen and (max-width: 996px) {
  .recommendedServices .servicesSearchHeading .selectionsModal {
    top: 120px;
  }
}

@media screen and (max-width: 640px) {
  .recommendedServices .servicesSearchHeading .selectionsModal {
    top: 90px;
  }
}

.kabelDocuments {
  box-sizing: border-box;
}

@media screen and (min-width: 640px) {
  .kabelDocuments {
    padding: 106px 30px 66px;
  }
}

@media screen and (max-width: 640px) {
  .kabelDocuments {
    padding: 45px 18px 0 18px;
  }
}

@media screen and (min-width: 640px) {
  .kabelDocuments h1 {
    margin-bottom: 88px;
  }
}

@media screen and (max-width: 640px) {
  .kabelDocuments h1 {
    margin-bottom: 40px;
  }
}

@media screen and (min-width: 640px) {
  .newsletterDownloads {
    padding: 0 30px 106px 30px;
  }
}

@media screen and (max-width: 640px) {
  .newsletterDownloads {
    padding: 0 18px 45px;
  }
}

.newsletterDownloads h1 {
  margin-bottom: 40px;
}

.ajaxContainer {
  position: relative;
  min-height: 300px;
}

.ajaxContainer h1, .ajaxContainer .newsletterList {
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}

.ajaxContainer.transitioning h1, .ajaxContainer.transitioning .newsletterList {
  opacity: 0;
}

.loadingSpinner,
.loadingSpinner:after {
  border-radius: 100%;
  width: 32px;
  height: 32px;
}

.loadingSpinner {
  margin: 0;
  font-size: 10px;
  text-indent: -9999em;
  border-top: 4px solid rgba(31, 176, 240, 0.2);
  border-right: 4px solid rgba(31, 176, 240, 0.2);
  border-bottom: 4px solid rgba(31, 176, 240, 0.2);
  border-left: 4px solid #1fb0f0;
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  animation: load8 1.1s infinite linear;
  position: absolute;
  display: none;
}

@-webkit-keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media screen and (min-width: 640px) {
  .contact {
    padding: 106px 30px;
  }
}

@media screen and (max-width: 640px) {
  .contact {
    padding: 46px 30px;
  }
}

.contact h1 {
  color: #1fb0f0;
}

@media screen and (min-width: 640px) {
  .contact h1 {
    margin-bottom: 60px;
  }
}

@media screen and (max-width: 640px) {
  .contact h1 {
    margin-bottom: 35px;
  }
}

.contactBox {
  width: 100%;
  float: left;
  background-color: #dee6e9;
}

@media screen and (min-width: 1024px) {
  .contactBox {
    max-width: 304px;
  }
}

.contactBox__top, .contactBox__bottom {
  padding: 24px 20px 6px;
  box-sizing: border-box;
}

.contactBox__top a, .contactBox__bottom a {
  display: block;
}

.contactBox__top p {
  margin-bottom: 20px;
}

.contactBox__top a {
  color: #0c1830;
  letter-spacing: -.5px;
}

.contactBox__bottom {
  background-color: #0c1830;
}

.contactBox__bottom a {
  margin-bottom: 20px;
}

.contactBox__bottom .mailTo {
  transition: color 0.3s ease-in-out;
}

.contactBox__bottom .mailTo:hover {
  color: white;
}

@media screen and (min-width: 640px) {
  .contactBox i {
    margin-right: 8px;
  }
}

.contactBox__phone {
  margin-bottom: 20px;
}

@media screen and (min-width: 640px) {
  .aboutSectionSelector {
    margin-top: 80px;
  }
}

@media screen and (max-width: 640px) {
  .aboutSectionSelector {
    margin-top: 40px;
  }
}

@media screen and (max-width: 640px) {
  .aboutOverview {
    margin-bottom: 40px;
  }
}

@media screen and (min-width: 640px) {
  .aboutOverview {
    margin-bottom: 80px;
  }
}

.aboutOverview .title {
  margin-bottom: 20px;
}

.aboutGraphic {
  width: 100%;
  position: relative;
}

.aboutGraphic img {
  width: 100%;
}

@media screen and (max-width: 640px) {
  .aboutGraphic img {
    margin-top: 100px;
  }
}

.aboutGraphic__manifesto {
  position: absolute;
  width: 100%;
}

.manifesto__text {
  width: 62%;
}

@media screen and (max-width: 1286px) {
  .manifesto__text {
    padding-left: 40px;
  }
}

@media screen and (max-width: 1024px) {
  .manifesto__text {
    width: 52%;
    font-size: 22px;
    line-height: 34px;
  }
}

@media screen and (max-width: 768px) {
  .manifesto__text {
    width: 76%;
    font-size: 18px;
    line-height: 28px;
  }
}

@media screen and (max-width: 640px) {
  .manifesto__text {
    width: 76%;
    padding-left: 20px;
    font-size: 16px;
    line-height: 24px;
  }
}

@media screen and (min-width: 640px) {
  .aboutTeam {
    padding: 162px 30px;
  }
}

@media screen and (max-width: 1024px) {
  .aboutTeam {
    padding: 110px 30px;
  }
}

@media screen and (max-width: 640px) {
  .aboutTeam {
    padding-top: 40px;
    padding-bottom: 50px;
    margin-bottom: 40px;
  }
}

.team__title strong {
  color: #222461;
}

@media screen and (min-width: 640px) {
  .team__title {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 640px) {
  .team__title {
    margin-bottom: 40px;
  }
}

.aboutOverlay {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 350;
  background-color: #000;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease-in-out;
}

.aboutOverlay.active {
  opacity: .6;
  pointer-events: all;
}

.timelineContainer {
  background-color: #F2F2F2;
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: max-height 0.4s ease 0s;
}

.zmdi {
  transition: transform 0.3s ease-in-out;
}

.zmdi.open {
  transform: rotate(180deg);
}

.slidePanel__box.about strong {
  color: #1fb0f0;
}

.modal.aboutModal {
  position: absolute;
  transform: translate3d(-50%, 0, 0);
}

.aboutSlider {
  backface-visibility: hidden;
}

.containerWidth.landingPage {
  width: 90%;
  max-width: 1068px;
}

@media screen and (max-width: 640px) {
  .containerWidth.landingPage {
    padding-top: 24px;
    padding-bottom: 18px;
  }
}

@media screen and (min-width: 640px) {
  .containerWidth.landingPage {
    padding-top: 128px;
    padding-bottom: 62px;
  }
}

.containerWidth.landingPage p, .containerWidth.landingPage h1 {
  color: #667682;
}

.containerWidth.landingPage h2 {
  margin-bottom: 48px;
}

.containerWidth.landingPage p {
  margin-bottom: 48px;
}

.containerWidth.landingPage .noM {
  margin-bottom: 0;
}

.containerWidth.landingPage .landingPage__image {
  width: 40%;
  max-width: 218px;
  margin-bottom: 12px;
  border-radius: 100%;
  border: 4px solid #e1e9ec;
}

@media screen and (max-width: 640px) {
  .containerWidth.landingPage .landingPage__image {
    transform: translateX(-8px);
  }
}

@media screen and (min-width: 640px) {
  .containerWidth.landingPage .landingPage__image {
    transform: translateX(-18px);
  }
}

.containerWidth.landingPage .landingPage__signature {
  width: 30%;
  max-width: 260px;
  margin-top: 12px;
}

@media screen and (max-width: 640px) {
  .containerWidth.landingPage .landingPage__signature {
    transform: translateX(-8px);
  }
}

@media screen and (min-width: 640px) {
  .containerWidth.landingPage .landingPage__signature {
    transform: translateX(-20px);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(1.5rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-1.5rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes scaleUp {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

@keyframes scaleDown {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(0);
  }
}

@keyframes slideUp {
  from {
    transform: translateY(25vh);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes slideDown {
  from {
    transform: translateY(-25vh);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes slideLeft {
  from {
    transform: translateX(25vh);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slideRight {
  from {
    transform: translateX(-25vh);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes flip {
  from {
    transform: scaleY(0);
  }
  to {
    transform: scaleY(1);
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes flash {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.25;
  }
}

@keyframes shake {
  15% {
    transform: translateX(0.5rem);
  }
  30% {
    transform: translateX(-0.4rem);
  }
  45% {
    transform: translateX(0.3rem);
  }
  60% {
    transform: translateX(-0.2rem);
  }
  75% {
    transform: translateX(0.1rem);
  }
  90% {
    transform: translateX(0);
  }
  90% {
    transform: translateX(0);
  }
}

.fadeIn {
  animation: fadeIn 500ms;
}

.fadeOut {
  animation: fadeOut 500ms;
}

.fadeInUp {
  animation: fadeInUp 500ms;
}

.fadeInDown {
  animation: fadeInDown 500ms;
}

.slideUp {
  animation: slideUp 200ms ease-in-out;
}

.slideDown {
  animation: slideDown 200ms ease-in-out;
}

.slideRight {
  animation: slideRight 200ms ease-in-out;
}

.slideLeft {
  animation: slideLeft 200ms ease-in-out;
}

.scaleUp {
  animation: scaleUp 200ms ease-in-out;
}

.scaleDown {
  animation: scaleDown 200ms ease-in-out;
}

.flipIn {
  animation: flip 200ms cubic-bezier(0.5, -0.5, 0.5, 1.5) forwards, fadeIn 400ms forwards;
}

.flipOut {
  animation: flip 200ms cubic-bezier(0.5, -0.5, 0.5, 1.5) backwards, fadeIn 400ms backwards;
}

.rotateRight {
  animation: rotate 500ms;
}

.rotateLeft {
  animation: rotate 500ms backwards;
}

.flash {
  animation: flash 500ms;
}

.shake {
  animation: wobble 200ms;
}

.paleSky {
  color: #667682;
}

.coldBurst {
  color: #222461;
}

.haiti {
  color: #0c1830;
}

.mystic {
  color: #dee6e9;
}

.pictonBlue {
  color: #1fb0f0;
}

.white {
  color: #fff;
}

.black {
  color: #000;
}

.orange {
  color: #f37522;
}

.malachite {
  color: #16d772;
}

.outerSpace {
  color: #293239;
}

.pineGlade {
  color: #B8C895;
}

.fuelYellow {
  color: #f0a41f;
}

.concrete {
  color: #F2F2F2;
}

body.explorer .weather__icon {
  margin-top: 0;
  height: 20px;
}

body.explorer .weather__city {
  margin-bottom: 10px;
}

body.explorer .servicesPhrase .selected__tooltip {
  margin-left: -132%;
}

body.explorer .newsletter.threeItemsSelector .threeItemsSelector__item {
  background-position: 4px center;
}

body.explorer .newsletter.threeItemsSelector .threeItemsSelector__item:nth-child(1),
body.explorer .newsletter.threeItemsSelector .threeItemsSelector__item:nth-child(2) {
  background-size: 104px;
}

body.explorer .newsletter.threeItemsSelector .threeItemsSelector__item:nth-child(3) {
  background-size: 106px;
}

body.explorer .newsDownload__img {
  border: none;
  outline: 0;
}

body.explorer .footer .fpa-logo {
  height: 36px;
}

body.explorer .animatedSliderComponent .controls__right {
  margin-left: 3px;
}

body.explorer .animatedSliderComponent .controls__right .zmdi {
  line-height: 36px;
}

body.explorer .animatedSliderComponent .controls__left .zmdi {
  line-height: 36px;
}

body.explorer .timeline .modal .teamBlock,
body.explorer .aboutModal .teamBlock {
  float: inherit;
}
