/////////////////////////////////////
// About Page ///////////////////////
/////////////////////////////////////

.aboutSectionSelector {
  @include large() {
    margin-top: 80px;
  }

  @include small() {
    margin-top: 40px;
  }
}

.aboutOverview {
  @include small() {
    margin-bottom: 40px;
  }

  @include large() {
    margin-bottom: 80px;
  }

  .title {
    margin-bottom: 20px;
  }
}

.aboutGraphic {
  width: 100%;
  position: relative;

  img {
    width: 100%;

    @include small() {
      margin-top: 100px;
    }
  }

  &__manifesto {
    position: absolute;
    width: 100%;
  }
}

.manifesto {
  &__text {
    width: 62%;

    @include customMedia(1286px) {
      padding-left: 40px;
    }

    @include medium() {
      width: 52%;
      font-size: 22px;
      line-height: 34px;
    }

    @include customMedia(768px) {
      width: 76%;
      font-size: 18px;
      line-height: 28px;
    }

    @include small() {
      width: 76%;
      padding-left: 20px;
      font-size: 16px;
      line-height: 24px;
    }
  }
}

.aboutTeam {
  @include large() {
    padding: 162px 30px;
  }

  @include medium() {
    padding: 110px 30px;
  }

  @include small() {
    padding-top: 40px;
    padding-bottom: 50px;
    margin-bottom: 40px;
  }
}

.team {
  &__title {

    strong {
      color: $coldBurst;
    }

    @include large() {
      margin-bottom: 80px;
    }

    @include small() {
      margin-bottom: 40px;
    }
  }
}

.aboutOverlay {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 350;
  background-color: $black;
  opacity: 0;
  pointer-events: none;

  @include animate(opacity);

  &.active {
    opacity: .6;
    pointer-events: all;
  }
}

.timelineContainer {
  background-color: $concrete;
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: max-height 0.4s ease 0s;
}

.zmdi {
  @include animate(transform);

  &.open {
    transform: rotate(180deg);
  }
}

.slidePanel__box.about {
  strong {
    color: $pictonBlue;
  }
}

.modal.aboutModal {
  position: absolute;
  transform: translate3d(-50%, 0, 0);
}

.aboutSlider {
  backface-visibility: hidden;
}