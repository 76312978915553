/////////////////////////////////////
// Contact Us ///////////////////////
/////////////////////////////////////

.contactUs, .introText {
  @include borderBox();

  @include large() {
    padding: 102px 30px;
  }

  .cardo--53 {
    @include large() {
      margin-bottom: 50px;
    }

    @include small() {
      margin-bottom: 27px;
    }
  }

  &--white {
    background-color: $white;

    .cardo--53 {
      color: $pictonBlue;
    }
  }

  &--cyan {
    background-color: $pictonBlue;

    .cardo--53 {
      color: $white;
    }
  }

  &--outerSpace {
    background-color: $outerSpace;

    .cardo--53 {
      color: $white;
    }
  }

  &--concrete {
    background-color: $concrete;

    .cardo--53 {
      color: $paleSky;
    }

    a, strong {
      color: $malachite;
    }

    strong {
      font-weight: normal;
    }

    i {
      margin-left: 6px;
    }
  }
}

.contactUs {
  @include small() {
    padding: 43px 18px 64px;
  }
}

.introText {
  @include small() {
    padding: 43px 18px 26px;
  }
}