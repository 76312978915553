/////////////////////////////////////
// Footer ///////////////////////////
/////////////////////////////////////

$footer-breakpoint: 960px;

.footer__col {
  float: left;
  @include borderBox();

  @include customLarge($footer-breakpoint) {
    width: $oneThird;
  }

  @include customMedia($footer-breakpoint) {
    width: 100%;
  }
}

.openSans--R20,
.openSans--SB20 {

  .footer__col &,
  &.footer__by {
    @include customMedia($footer-breakpoint) {
      font-size: 14px;
      line-height: 25px;
    }

  }
}

.footer__col--left {

  @include customLarge($footer-breakpoint) {
    text-align: left;
  }

  @include customMedia($footer-breakpoint) {
    text-align: center;
  }
}

.footer__col--right {

  @include customLarge($footer-breakpoint) {
    text-align: right;
  }

  @include customMedia($footer-breakpoint) {
    text-align: center;
  }
}

.footer {
  text-align: center;

  &__top,
  &__bottom {
    background-color: $mystic;
  }

  .fpa-logo {
    max-width: 245px;
  }

  .mobileHidden {
    @include customMedia($footer-breakpoint) {
      display: none;
    }
  }

  .mobileOnly {
    @include customLarge($footer-breakpoint) {
      display: none;
    }

    @include customMedia($footer-breakpoint) {
      display: block;
    }
  }
}

.footer__top {
  border-bottom: 3px solid $white;

  @include customLarge($footer-breakpoint) {
    padding: 0 $gutter;
  }

  @include customMedia($footer-breakpoint) {
    padding: 0 $gutterMobile;
  }

  .footer__col--left,
  .footer__col--right {
    @include customLarge($footer-breakpoint) {
      padding-top: 30px;
    }
  }

  .footer__col--right {
    @include customMedia($footer-breakpoint) {
      padding: 16px 0 35px;
    }
  }

  .footer__col--center {

    @include customLarge($footer-breakpoint) {
      padding: 52px 0;
    }

    @include customMedia($footer-breakpoint) {
      padding: 30px 0;
    }
  }

}

.footerResources {
  color: $paleSky;
  display: inline-block;
  @include animate(color);

  &:hover {
    color: $haiti;
  }

  .zmdi,
  .sackers--19 {
    display: inline-block;
    float: left;
  }

  .zmdi {
    font-size: 40px;
    margin-right: 18px;
  }

  .sackers--19 {
    margin-top: 12px;
  }
}

.footer__logo {
  width: 100%;

  @include customLarge($footer-breakpoint) {
    max-width: 458px;
  }

  @include customMedia($footer-breakpoint) {
    max-width: 293px;
  }
}

.fpa-logo {
  display: inline-block;
  width: 100%;

  &:hover {
    #fpaLogo path {
      fill: $paleSky;
    }
  }
}

#fpaLogo path {
  fill: $black;
  @include animate();
}

.footer__bottom {

  @include customLarge($footer-breakpoint) {
    padding: 65px $gutter 40px;
  }

  @include customMedia($footer-breakpoint) {
    padding: 43px $gutterMobile 46px;
  }

  .zmdi {
    font-size: 19px;
  }

  .footer__col--center {
    color: $haiti;

    @include customLarge($footer-breakpoint) {
      padding: 0 $gutter;
      @include borderBox();
    }

    @include customMedia($footer-breakpoint) {
      margin-bottom: 18px;
    }

    a {
      color: $haiti;
      @include animate(color);

      &:hover {
        color: $pictonBlue;
      }
    }
  }

  .mobileOnly {
    margin-bottom: 20px;
  }
}

.footer__social {
  margin-top: 10px;

  .zmdi {
    @include customLarge($footer-breakpoint) {
      font-size: 36px;
      padding: 0 7px;
    }

    @include customMedia($footer-breakpoint) {
      font-size: 30px;
      padding: 0 7px;
    }
  }
}

.footer__maps {
  text-align: center;
  background-color: $haiti;
  color: $white;

  @include customLarge($footer-breakpoint) {
    padding: 19px $gutter;
  }

  @include customMedia($footer-breakpoint) {
    padding: 19px $gutterMobile;
  }

  .zmdi, a {
    color: $pictonBlue;
  }

  a {
    @include animate(color);

    &:hover {
      color: $white;
    }
  }
}

.footer__by {
  color: $paleSky;
  padding: 19px $gutter;

  a {
    color: $paleSky;
    @include animate();

    &:hover {
      color: $haiti;
    }
  }
}

.securityAndPrivacy {
  @include animate(color);
  cursor: pointer;

  &:hover {
    color: $haiti;
  }

  &__container {
    padding: 40px;
    margin-top: 40px;
    margin-bottom: 20px;

    a {
      color: $malachite;
      text-decoration: underline;
    }
  }
}