/////////////////////////////////////
// Mixins ///////////////////////////
/////////////////////////////////////

@mixin verticalAlign($top: 50%, $position: relative) {
  position: $position;
  top: $top;
  transform: translateY(-50%);
}

@mixin horizontalAlign($left: 50%, $position: relative) {
  position: $position;
  left: $left;
  transform: translateX(-50%);
}

@mixin borderBox() {
  box-sizing: border-box;
}

@mixin animate($property: all) {
  transition: $property .3s ease-in-out;
}

@mixin borderRadius($border, $radius:5px) {
  @if $border == top {
    border-top-left-radius: $radius;
    border-top-right-radius: $radius;
  }
  @else if $border == bottom {
    border-bottom-left-radius: $radius;
    border-bottom-right-radius: $radius;
  }
  @else {
    border-radius: $radius;
  }
}

@mixin multiLineEllipsis($lineHeight: 1.2em, $lineCount: 1, $bgColor: white) {
  overflow: hidden;
  position: relative;
  line-height: $lineHeight;
  max-height: $lineHeight * $lineCount;
  text-align: left;
  margin-right: -1em;
  padding-right: 1em;
  &:before {
    content: '...';
    position: absolute;
    right: 0;
    bottom: 0;
  }
  &:after {
    content: '';
    position: absolute;
    right: 0;
    width: 1em;
    height: 1em;
    margin-top: 0.2em;
    background: $bgColor;
  }
}