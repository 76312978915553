/////////////////////////////////////
// Variables ////////////////////////
/////////////////////////////////////

$small-break: 640px !default;
$medium-break: 1024px !default;
$navBreak: 996px;

// Measures

$gutter: 23px;
$gutterMobile: 18px;
$oneThird: 100% / 3;

// Fonts

$sackers: 'SackersGothicStd-Heavy', sans-serif;
$openSans: 'Open Sans', sans-serif;
$cardo: 'Cardo', serif;
$materialIcons: normal normal normal 14px/1 'Material-Design-Iconic-Font';


// Weights

$light: 300;
$regular: 400;
$semiBold: 600;
$bold: 700;

// Colors

$paleSky: #667682;
$coldBurst: #222461;
$haiti: #0c1830;
$mystic: #dee6e9;
$pictonBlue: #1fb0f0;
$white: #fff;
$black: #000;
$orange: #f37522;
$malachite: #16d772;
$outerSpace: #293239;
$pineGlade: #B8C895;
$fuelYellow: #f0a41f;
$concrete: #F2F2F2;