/////////////////////////////////////
// Forms ////////////////////////////
/////////////////////////////////////

input, select, textarea, button {
  font-family: $openSans;
  font-weight: $regular;
  @include borderBox();
  border-image: none;
  -webkit-appearance: none;
  border: 0;

  &:focus {
    box-shadow: none;
    outline: 0 none;
  }
}

button {
  cursor: pointer;
  @include animate();
}

input.input--white {
  font-size: 16px;
  height: 55px;
  padding: 0 12px;
  border-radius: 4px;
  border-width: 0;
  background-color: $white;
  color: $outerSpace;

  &::-webkit-input-placeholder {
    color: $outerSpace !important;
  }
}

input {
  &.input--services {
    font-size: 20px;
    border-bottom-width: 4px;
    border-bottom-color: $haiti;
    border-bottom-style: solid;
    padding: 0 0 10px;

    @include customLarge($medium-break) {
      min-width: 460px;
    }

    @include medium() {
      min-width: 240px;
    }

    &::-webkit-input-placeholder {
      color: $paleSky !important;
    }
  }
}

.submit--green {
  font-weight: $semiBold;
  font-size: 16px;
  height: 55px;
  padding: 0 33px;
  border-radius: 4px;
  border-width: 0;
  background-color: $malachite;
  color: $white;

  &:hover {
    background-color: $white;
    color: $malachite;
  }
}

// Input Focuser
.kabelForm {
  margin-bottom: 56px;
  @include animate();

  .textField, textarea {
    width: 100%;
  }

  .textField {
    height: 54px;

    &.error + label {
        color: red;
    }
  }

  textarea {
    padding-top: 22px;
  }

  .inputBox {

    &.field {
      @include large() {
        width: 46%;
        &:nth-child(even) {
          margin-left: 4%;
        }
      }
      @include small() {
        width: 100%;
      }
      margin-bottom: 50px;
      position: relative;
      float: left;
    }

    &.text {
      @include large() {
        width: 96%;
      }
      @include small() {
        width: 100%;
      }
      margin-bottom: 50px;
      position: relative;
      float: left;
    }

    &.submit {
      width: 96%;
      float: left;
    }

    &.text:after, &.field:after {
      content: '';
      width: 100%;
      height: 1px;
      position: absolute;
      bottom: -1px;
      left: 0;
      background-color: $mystic;
      z-index: 2;
      @include animate();
    }

    &.active {
      &:after {
        content: '';
        width: 100%;
        height: 3px;
        position: absolute;
        bottom: -3px;
        left: 0;
        background-color: $pictonBlue;
        z-index: 2;
      }
    }
  }

  .inputBox {
    label {
      position: absolute;
      z-index: 3;
      left: 0;
      top: 0;
      transform: translate3d(0, 50%, 0);
      color: $haiti;
      @include animate();
    }

    &.active {
      label {
        transform: translate3d(0, -50%, 0);
        color: $pictonBlue;
        font-size: 13px;
      }
    }
  }
}