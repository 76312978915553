/////////////////////////////////////
// Modifiers ////////////////////////
/////////////////////////////////////

@mixin flexCenter($flexDirection: row, $alignItems: center) {
  display: flex;
  align-items: $alignItems;
  justify-content: center;
  align-content: center;
  flex-direction: $flexDirection;
}

@mixin flexSquaresContainer($justifyContent: flex-start) {
  display: flex;
  flex-flow: row wrap;
  justify-content: $justifyContent;
  align-items: stretch;
  align-content: flex-start;
}

@mixin flexSquaresItemWidth($width) {
  width: $width;
  max-width: $width;
  flex: 1 0 ($width - 1%);
}

@mixin verticalCenter() {
  top: 50%;
  transform: translateY(-50%);
}

@mixin horizontalCenter() {
  left: 50%;
  transform: translateX(-50%);
}

@mixin verticalAndHorizontalCenter() {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin borderBox() {
  box-sizing: border-box;
}

@mixin linkUnderlinerAnimated($lineMargin, $lineWidth: 1px, $lineColor: #000) {
  display: inline-block;
  text-decoration: none;
  box-sizing: border-box;

  &:after {
    content: '';
    display: block;
    width: 0;
    margin-top: $lineMargin;
    padding-top: $lineWidth;

    background-color: $lineColor;

    transition: width 0.6s cubic-bezier(0.4, 0, 0.2, 1);
  }

  &:hover {
    &:after {
      width: 100%;
    }
  }
}

@mixin linkUnderliner($lineMargin, $lineWidth: 1px, $lineLength: 100%, $lineColor: #000) {
  display: inline-block;
  text-decoration: none;
  box-sizing: border-box;

  &:after {
    content: '';
    display: block;
    width: $lineLength;
    margin-top: $lineMargin;
    padding-top: $lineWidth;

    background-color: $lineColor;
  }
}

%absolutePosition {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

%fixedPosition {
  position: fixed;
  top: 0;
  left: 0;
}

@mixin clearfix() {
  &:before,
  &:after {
    content: " ";
    display: table;
  }

  &:after {
    clear: both;
  }
}

@mixin boxify($color, $backgroundColor: $white) {
  border-style: solid;
  border-width: 1px;
  border-color: $black;
  border-top-width: 17px;
  border-top-color: $color;
  background-color: $backgroundColor;
}

@mixin outerGutters() {
  @include borderBox();

  @include large() {
    padding: 0 $gutter;
  }

  @include small() {
    padding: 0 $gutterMobile;
  }
}