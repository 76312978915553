/////////////////////////////////////
// Newsletter List //////////////////
/////////////////////////////////////

.newsletterList {
  width: 100%;

  @include large() {
    margin-bottom: 38px;
  }

  &__item {
    padding: 14px;
    border-top: 2px solid $mystic;
    @include borderBox();
    @include animate();
    cursor: pointer;

    &:hover {
      background-color: $haiti;

      .itemTitle {
        color: $white;
      }
    }
  }

  .itemTitle {
    @include animate();
  }

  .itemDate {
    color: $pineGlade;
  }
}