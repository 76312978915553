.modal {
  width: 100%;
  max-width: 732px;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 400;
  transform: translate3d(-50%, -50%, 0);
  background-color: $white;

  opacity: 0;
  pointer-events: none;

  @include animate();

  &__close {
    padding: 20px;
    position: absolute;
    top: 0;
    right: 10px;
    font-size: 32px;
    cursor: pointer;
  }

  &.active {
    opacity: 1;
    pointer-events: all;
  }
}