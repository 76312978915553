/////////////////////////////////////
// Newsletter ///////////////////////
/////////////////////////////////////


.newsletters {
  background-color: $outerSpace;
  color: $white;
  text-align: center;

  @include large() {
    padding: 83px 30px;
  }

  @include small() {
    padding: 30px $gutterMobile;
  }

  .cardo--53 {
    @include large() {
      margin-bottom: 94px;
    }

    @include small() {
      margin-bottom: 28px;
    }
  }

  .input--white {
    margin-right: 10px;

    @include small() {
      margin-bottom: 10px;
      width: 100%;
    }
  }
}

.newsBoxes {
  @include large() {
    margin-bottom: 78px;
  }

  @include small() {
    margin-bottom: 40px;
  }
}

.newsDownload {
  @include borderBox();
  float: left;
  width: $oneThird;

  @include customLarge(780px) {
    width: $oneThird;
    padding: 0 40px;
  }

  @include customMedia(780px) {
    width: 100%;
    padding: 0 $gutterMobile;
  }

  &:nth-child(2), &:nth-child(3) {
    @include customMedia(780px) {
      display: none;
    }
  }
}

.newsDownload__img {
  margin-bottom: 32px;
  border: 1px solid $mystic;
  width: 100%;
  max-width: 244px;
  margin-left: auto;
  margin-right: auto;
  transform: translateY(0);
  @include animate();

  &:hover {
    transform: translateY(-30px);
  }

  img {
    border: 1px solid $mystic;
    max-width: 100%;
    width: 100%;
    float: left;
  }
}

.newsletterSubscription {
  margin-bottom: 35px;
  min-height: 55px;
  @include animate();
}

$newsFormBreak: 860px;

#newsMail {
  @include customLarge($newsFormBreak) {
    width: 37.5%;
    max-width: 284px;
  }
}

#newsFirstName,
#newsLastName {
  @include customLarge($newsFormBreak) {
    width: 18.75%;
    max-width: 146px;
  }
}

#newsSubmit {
  @include customLarge($newsFormBreak) {
    width: 25%;
    max-width: 167px;
  }
}

#newsMail,
#newsFirstName,
#newsLastName {
  @include customMedia($newsFormBreak) {
    width: 80%;
    margin-bottom: 18px;
  }

  @include small() {
    width: 100%;
    margin-bottom: 18px;
  }
}