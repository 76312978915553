/////////////////////////////////////
// Landing Page /////////////////////
/////////////////////////////////////

.containerWidth.landingPage {
	width: 90%;
	max-width: 1068px;

	@include small() {
		padding-top: 24px;
		padding-bottom: 18px;
	}

	@include large() {
		padding-top: 128px;
		padding-bottom: 62px;
	}

	p, h1 {
		color: $paleSky;
		@extend .cardo--30;
	}

	h2 {
		@extend .openSans--SB20;
		margin-bottom: 48px;
	}

	p {
		margin-bottom: 48px;
	}

	.noM {
		margin-bottom: 0;
	}

	.landingPage__image {
		width: 40%;
		max-width: 218px;
		margin-bottom: 12px;
		border-radius: 100%;
		border: 4px solid #e1e9ec;

		@include small() {
			transform: translateX(-8px);
		}
		
		@include large() {
			transform: translateX(-18px);
		}
	}

	.landingPage__signature {
		width: 30%;
		max-width: 260px;
		margin-top: 12px;

		@include small() {
			transform: translateX(-8px);
		}
		
		@include large() {
			transform: translateX(-20px);
		}
	}
}