/*-------------------------------------------------------*\
    Variables
/*-------------------------------------------------------*/

/*	Grid
--------------------------------------------------------*/

$columns: 12 !default;
$column-width: 70px !default;
$gutter-width: 30px !default;

// Basic or Advanced usage
$advanced-usage: true !default;